<template>
  <div class="house-page">
    <!--main-position start-->
    <div class="main-position w-1200">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>业绩目标</el-breadcrumb-item>
        <el-breadcrumb-item class="blue_color">查看客源</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--main-position end-->
    <!--house-main start-->
    <div class="house-main w-1400">
      <div class="house-container w-1200">
        <!--house-table start-->
        <div class="headCont">
          <div class="left">
            <div v-for="item in navList" :key="item.id" class="list" :class="item.id==navId?'active':''" @click="onNavClick(item.id)">
              {{item.title}}
              <div class="xian"></div>
            </div>
          </div>
          <div class="right">
            <el-input placeholder="请输入搜索内容" class="input-with-select" v-model="from.keywords">
              <i class="el-icon-search el-input__icon" slot="prefix"> </i>
              <el-button slot="append" @click="onSearch">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="house-table">
          <el-table :data="tableData" style="width: 100%" stripe :default-sort="{ prop: 'houseType' }">
            <el-table-column label="姓名">
              <template slot-scope="scope">
                <div class="tourist-name">
                  <span class="name m-r-10">{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="sex" min-width="50" label="性别"> </el-table-column>
            <el-table-column prop="purpose_house_type" label="房型" sortable>
            </el-table-column>
            <el-table-column prop="finish_type" label="装修"> </el-table-column>
            <el-table-column prop="total_price" label="预算" sortable>
            </el-table-column>
            <el-table-column prop="broker_name" label="经纪人">
            </el-table-column>
            <el-table-column prop="dian_name" label="委托店面">
            </el-table-column>
            <el-table-column prop="city" min-width="150" label="意向位置">
            </el-table-column>
            <el-table-column prop="look_num" min-width="100" label="10天带看" sortable>
            </el-table-column>
            <el-table-column prop="createtime" min-width="130" label="添加日期" sortable>
            </el-table-column>
            <el-table-column min-width="130" label="跟进日期" sortable>
              <template slot-scope="scope">{{$filter.to_date_time(scope.row.updatetime)}}</template>
            </el-table-column>
          </el-table>
          <div class="paging" v-if="tableData.length">
            <el-pagination background @current-change="changePage" layout="prev, pager, next, jumper, ->, total" :total="total">
            </el-pagination>
          </div>
        </div>
        <!--house-table end-->
      </div>
    </div>
    <!--house-main end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "city"]),
  },
  data () {
    return {
      total: 0, //条数
      tableData: [],
      from: {
        page: 1,// 页数
        pageNum: 15,// 条数
        id: '',// 查看人的id
        type: '2',// 1=公客2=私客
        keywords: '',// 名称搜索
      },
      navId: 2,
      navList: [{ id: 2, title: '私客' }, { id: 1, title: '公客' }]
    };
  },
  methods: {
    changePage (e) {
      this.from.page = e
      this.getList()
    },
    //获取列表
    getList () {
      this.$axios.TargetClientList(this.from).then(res => {
        this.tableData = res.data.list.data;
        this.total = res.data.list.total;
      })
    },
    // 点击切换
    onNavClick (id) {
      this.navId = id
      this.from.type = id
      this.from.page = 1
      this.getList()
    },
    onSearch () {
      this.from.page = 1
      this.getList()
    }
  },
  created () {
    this.from.id = this.$route.query.uid;
    // this.from.type = this.$route.query.type;
    // this.from.city = this.city;
    this.getList();
  },
};
</script>

<style scoped lang="less">
.main-position {
  display: flex;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
  margin: 0 auto;
  .s {
    margin: 0 3px;
  }
}
.personage_head {
  padding: 10px 0 30px 0px;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.house-main {
  margin: 0 auto 40px auto;
  background: #fff;
}
.house-container {
  padding: 40px 0 30px 0;
  margin: 0 auto;
  /deep/ .el-table th.el-table__cell.is-leaf {
    background: #f5f5f5;
    border: none;
    font-size: 14px;
    color: #333;
    font-weight: normal;
  }
  /deep/ .el-table {
    color: #333;
  }
  /deep/ .el-table td.el-table__cell {
    border-bottom: 1px solid #ebebeb;
  }
  /deep/
    .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background: #fafcff;
  }
  .house-img {
    position: relative;
    .img {
      width: 104px;
      height: 78px;
      border-radius: 4px;
    }
    .pos {
      position: absolute;
      left: 0px;
      top: 0;
      border-radius: 4px 0px 4px 0px;
      background: rgba(0, 0, 0, 0.3);
      line-height: 28px;
      font-size: 12px;
      color: #fff;
      padding: 0 10px;
    }
  }
  .house-person {
    .c-btn {
      width: 58px;
      height: 32px;
      background: #cccccc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .icon {
        width: 14px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }
  .house-name {
    .name {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tags {
      display: flex;
      margin: 6px -3px;
      .tag {
        line-height: 24px;
        background: #f5f5f5;
        border-radius: 3px;
        padding: 0 10px;
        font-size: 12px;
        color: #999;
        margin: 3px;
        white-space: nowrap;
      }
      .tag.orange {
        background: rgba(252, 130, 20, 0.1);
        color: #fc8214;
      }
    }
    .tip {
      color: #999;
      display: flex;
      margin: 0 -5px;
      .s {
        white-space: nowrap;
        margin: 0 5px;
        line-height: 1.6;
      }
    }
  }
  .tourist-name {
    position: relative;
    display: flex;
  }
  .color_blue {
    color: #3273f6;
  }
}
.blue_color {
  /deep/ .el-breadcrumb__inner {
    color: #3273f6;
  }
}
.headCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .left {
    display: flex;
    .list {
      font-size: 18px;
      color: #333333;
      margin-right: 60px;
      cursor: pointer;
      &.active {
        color: #3273f6;
        .xian {
          width: 20px;
          height: 2px;
          background: #3273f6;
          margin: 7px auto 0;
        }
      }
    }
  }
  .right {
    /deep/ .el-input {
      width: 480px;
      height: 36px;
      .el-button {
        width: 100px;
        height: 38px;
        font-size: 18px;
        color: #fff;
        background: #3273f6;
      }
    }
  }
}
</style>
