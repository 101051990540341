<template>
  <div class="ment-page">
    <!--main-position start-->
    <div class="main-position w-1200">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>业绩统计</el-breadcrumb-item>
        <el-breadcrumb-item class="blue_color">新增带看</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--main-position end-->
    <!--ment-main start-->
    <div class="ment-main w-1200">
      <!--ment-side start-->
      <div class="ment-side">
        <div class="side-head">
          <div class="title">新增带看</div>
          <div class="side-nav">
            <div
              @click="changeTypes(item.type)"
              class="nav-li"
              v-for="(item, index) in typeList"
              :key="index"
            >
              <div class="left">
                <img :src="item.img" alt="" v-if="from.types != item.type" />
                <img :src="item.images" alt="" v-else />
                <span :class="{ active: from.types == item.type }">{{
                  item.name
                }}</span>
              </div>
              <img
                v-if="from.types == item.type"
                src="../../assets/image/personage/icon22.png"
                alt=""
              />
              <img
                v-else
                src="../../assets/image/personage/icon20.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!--side-list start-->
        <div class="side-list">
          <div class="title">大事件</div>
          <router-link tag="div" :to="{ path: '/business', query: { type: 2 } }" class="list-item" v-for="(item,index) in incidentList" :key="index">
            <div class="t">{{ item.title }}</div>
            <div class="c">时间：{{ item.createtime }}</div>
          </router-link>
        </div>
        <!--side-list end-->
      </div>
      <!--ment-side end-->
      <!--ment-content start-->
      <div class="ment-content">
        <div class="per-search flex">
          <i class="el-icon-search icon"></i>
          <div class="search-input">
            <el-input v-model="from.search_name" placeholder="请输入搜索内容"></el-input>
          </div>
          <el-button @click="getList">搜索</el-button>
        </div>
        <!--ment-list start-->
        <div class="ment-list">
          <!--ment-list-item start-->
          <div class="ment-list-item" v-for="(item,index) in list" :key="index">
            <div class="item">
              <div class="li">
                <span class="color-9">带看时间：</span> {{ item.createtime }}
              </div>
              <div class="li">
                <span class="color-9">带看客户：</span> {{ item.rec_name }}
              </div>
              <div class="li">
                <span class="color-9">联系方式：</span> {{ item.rec_mobile }}
              </div>
              <div class="li">
                <span class="color-9">经纪人：</span> {{ item.user_name }}   {{ item.broker_user_id }}
              </div>
            </div>
            <div class="item" v-for="(em,ind) in item.content" :key="em">
              <span class="color-9">房源{{ ind + 1 }}：</span>
              {{ em.village_name }}{{ em.house_info }}，{{ em.village_address }}
            </div>
            <div class="item">
              <span class="color-9">带看反馈：</span>
              {{ item.remark }}
            </div>
          </div>
          <!--ment-list-item end-->
        </div>
        <!--ment-list end-->
        <div class="paging" v-if="list.length">
          <el-pagination
            background
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!--ment-content end-->
    </div>
    <!--ment-main end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "city"]),
  },
  data() {
    return {
      total: 0, //条数
      list: [], //列表
      from: {
        type: 1,//1
        page: 1,
        city: "",
        search_name:'',
        types: 1, //1=客源2=房源
        house_type: 1, //房源类型：1新房；2 住宅；3 公寓；4 写字楼；5 商铺；6 别墅
      },
      typeList: [
        {
          name: "客源",
          type: 1,
          img: require("../../assets/image/personage/icon26.png"),
          images: require("../../assets/image/personage/icon17.png"),
        },
        {
          name: "房源",
          type: 2,
          img: require("../../assets/image/personage/icon18.png"),
          images: require("../../assets/image/personage/icon19.png"),
        },
      ],
      incidentList:[],//
    };
  },
  methods: {
    changePage (e) {
      this.from.page = e
      this.getList()
    },
    //切换类型
    changeTypes(type) {
      this.from.types = type;
      this.init();
    },
    init() {
      this.from.page = 1;
      this.list = [];
      this.getList();
    },
    getList() {
      this.$axios.indexDaiKan(this.from).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    //大事件列表
    getarticleList() {
      this.$axios
        .articleList({
          type: 1,
          city: this.from.city,
        })
        .then((res) => {
          this.incidentList = res.data.data;
        });
    },
  },
  created() {
    this.from.city = this.city;
    this.getList();
    this.getarticleList();
  },
};
</script>

<style scoped lang="less">
.per-search {
  width: 560px;
  border: 2px solid #ebebeb;
  height: 36px;
  position: relative;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 30px;
  .icon {
    color: #ccc;
    margin: 0 10px;
  }
  .search-input {
    width: 400px;
  }
  /deep/ .search-input .el-input__inner {
    border: none;
    padding: 0;
    height: 32px;
    padding: 0;
    line-height: 32px;
    font-size: 16px;
    text-align: left;
  }
  /deep/ .el-button {
    background: #3273f6;
    width: 100px;
    height: 36px;
    padding: 0;
    border: none;
    border-radius: 0;
    color: #fff;
    position: absolute;
    right: 0px;
    top: -2px;
    font-size: 18px;
  }
}
.personage_head {
  padding: 30px 30px;
  border-bottom: 1px solid #ebebeb;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.main-position {
  display: flex;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
  margin: 0 auto;
  .s {
    margin: 0 3px;
  }
}
.ment-main {
  margin: 0 auto 40px auto;
  display: flex;
  justify-content: space-between;
  .ment-side {
    width: 320px;
    .side-head {
      background: #fff;
      .title {
        background: url("../../assets/image/personage/a2.png") no-repeat center;
        background-size: cover;
        color: #fff;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        padding: 0 30px;
        position: relative;
      }
      .title:before {
        width: 4px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -10px;
        border-radius: 2px;
        background: #fff;
        display: block;
        content: "";
      }
      .side-nav {
        padding: 0 20px;
        .nav-li {
          padding: 20px 0px;
          cursor: pointer;
          border-bottom: 1px solid #ebebeb;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: space-between;
          &:last-child {
            border-bottom: none;
          }
          .left {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
            span {
              font-size: 16px;
            }
            .active {
              color: #3273f6;
            }
          }
          img {
            width: 8px;
            height: 14px;
          }
        }
      }
    }
    .side-list {
      margin: 20px 0px;
      background: #fff;
      padding: 10px 20px;
      .title {
        font-size: 18px;
        padding-left: 15px;
        position: relative;
        line-height: 2;
        background: url("../../assets/image/personage/icon20.png") no-repeat
          right center;
      }
      .title:before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -10px;
        background: #3273f6;
        display: block;
        width: 4px;
        height: 20px;
        border-radius: 2px;
        content: "";
      }
      .list-item {
        padding: 15px 0px;
        border-bottom: 1px solid #ebebeb;
        .t {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.6;
        }
        .c {
          font-size: 14px;
          color: #666;
          line-height: 1.6;
          margin-top: 5px;
        }
      }
      .list-item:nth-last-child(1) {
        border: none;
      }
    }
  }
  .ment-content {
    width: 860px;
    background: #fff;
  }
  .ment-list {
    padding: 20px 30px;
    .ment-list-item {
      padding: 15px 0px;
      border-bottom: 1px solid #ebebeb;
      font-size: 14px;
      line-height: 1.6;
      .item {
        display: flex;
        line-height: 1.6;
        margin: 5px 0px;
        span {
          flex-shrink: 0;
        }
      }
      .li {
        flex: 1;
        // min-width: 30%;
        line-height: 1.6;
      }
      span {
        line-height: 1.6;
      }
    }
  }
}
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}
.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}
.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}
.rate_page_btn.red {
  background: #fddfdf;
  border: 1px solid #fddfdf;
  color: #ff5151;
}
.personage_ul {
  padding: 0 30px;
  li {
    padding: 30px 0px;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .tip {
      font-size: 14px;
    }
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
.blue_color {
  /deep/ .el-breadcrumb__inner {
    color: #3273f6;
  }
}
</style>
