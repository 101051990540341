<template>
  <div class="personage">
    <div class="personage_box margin-auto w-1200 flex m-t-20">
      <div class="personage_nav">
        <!-- <el-menu
          unique-opened
          :default-active="activeIndex"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <template v-for="(item, index) in navList">
            <el-submenu :index="item.index" :key="index" v-if="item.children">
              <template slot="title">
                <img class="menu-icon" :src="item.iconImage" />
                <img class="menu-icons" :src="item.iconImages" />
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                :index="em.index"
                v-for="(em, ind) in item.children"
                :key="ind"
                >{{ em.name }}</el-menu-item
              >
            </el-submenu>
            <el-menu-item :index="item.index" v-else :key="index">
              <img class="menu-icon" :src="item.iconImage" />
              <img class="menu-icons" :src="item.iconImages" />
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-menu> -->
        <el-menu
          unique-opened
          :default-active="activeIndex"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="0" :disabled="userInfo.position == 5">
            <template slot="title">
                <img class="menu-icon" src="../../assets/image/personage/icon51.png" />
                <img class="menu-icons" src="../../assets/image/personage/icon52.png" />
                <span>约看记录</span>
              </template>
          </el-menu-item> 
          <el-submenu index="1" :disabled="userInfo.position == 5">
            <template slot="title">
              <img class="menu-icon" src="../../assets/image/personage/icon53.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon54.png" />
              <span>跟进记录</span>
            </template>
            <el-menu-item index="1-1">客源</el-menu-item>
            <el-menu-item index="1-2">房源</el-menu-item>
          </el-submenu>
          <el-submenu index="2" :disabled="userInfo.position == 5">
            <template slot="title">
              <img class="menu-icon" src="../../assets/image/personage/icon55.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon56.png" />
              <span>交易记录</span>
            </template>
            <el-menu-item index="2-1">线上签约</el-menu-item>
            <el-menu-item index="2-2">线下签约</el-menu-item>
          </el-submenu>
          <el-menu-item index="3" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon57.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon58.png" />
            <span slot="title">我的目标</span>
          </el-menu-item>
          <el-submenu index="4" :disabled="userInfo.position == 5">
            <template slot="title">
              <img class="menu-icon" src="../../assets/image/personage/icon59.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon60.png" />
              <span>审核状态</span>
            </template>
            <el-menu-item index="4-1">合同审核</el-menu-item>
            <el-menu-item index="4-2">成交报告</el-menu-item>
            <el-menu-item index="4-3">信息变更</el-menu-item>
            <el-menu-item index="4-4">删除审核</el-menu-item>
            <el-menu-item index="4-5">添加小区</el-menu-item>
            <el-menu-item index="4-6">请假审核</el-menu-item>
            <el-menu-item index="4-7">晋升审核</el-menu-item>
            <el-menu-item index="4-8">报销审核</el-menu-item>
          </el-submenu>
          <el-menu-item index="5" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon61.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon62.png" />
            <span slot="title">考勤</span>
          </el-menu-item>
          <el-menu-item index="6" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon63.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon54.png" />
            <span slot="title">个人荣誉</span>
          </el-menu-item>
          <el-menu-item index="7" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon65.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon56.png" />
            <span slot="title">个人违规</span>
          </el-menu-item>
          <el-menu-item index="8" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon67.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon68.png" />
            <span slot="title">我的业绩</span>
          </el-menu-item>
          <el-menu-item index="9" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon69.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon70.png" />
            <span slot="title">我的关注</span>
          </el-menu-item>
          <el-menu-item index="10" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon71.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon72.png" />
            <span slot="title">黑名单</span>
          </el-menu-item>
          <el-menu-item index="11" :disabled="userInfo.position == 5">
            <img class="menu-icon" src="../../assets/image/personage/icon73.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon54.png" />
            <span slot="title">消息</span>
          </el-menu-item>
          <el-submenu index="12" :disabled="userInfo.position == 5">
            <template slot="title">
              <img class="menu-icon" src="../../assets/image/personage/icon59.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon60.png" />
              <span>设置</span>
            </template>
            <el-menu-item index="12-1">长期锁定记录</el-menu-item>
            <el-menu-item index="12-2">房源变更状态</el-menu-item>
            <el-menu-item index="12-3">修改密码</el-menu-item>
            <el-menu-item index="12-4">员工法则</el-menu-item>
            <el-menu-item index="12-5">关于我们</el-menu-item>
          </el-submenu>
          <el-menu-item index="13" :disabled="userInfo.position == 5 || userInfo.position == 4">
            <img class="menu-icon" src="../../assets/image/personage/icon77.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon78.png" />
            <span slot="title">团队人员</span>
          </el-menu-item>
          <el-submenu index="14" :disabled="userInfo.position != 5">
            <template slot="title">
              <img class="menu-icon" src="../../assets/image/personage/icon79.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon80.png" />
              <span>人事管理</span>
            </template>
            <el-menu-item index="14-1">员工管理</el-menu-item>
            <el-menu-item index="14-2">面试管理</el-menu-item>
          </el-submenu>
          <el-submenu index="15" :disabled="userInfo.position == 5 || ((userInfo.position === 4 || userInfo.position === 3) && userInfo.fang_type_privilege === 1)">
            <template slot="title">
              <img class="menu-icon" src="../../assets/image/personage/icon81.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon82.png" />
              <span>合同管理</span>
            </template>
            <el-menu-item index="15-1">合同领取</el-menu-item>
            <el-menu-item index="15-2">合同上传</el-menu-item>
          </el-submenu>
          <el-submenu index="16" :disabled="userInfo.position == 5">
            <template slot="title">
              <img class="menu-icon" src="../../assets/image/personage/icon83.png" />
              <img class="menu-icons" src="../../assets/image/personage/icon84.png" />
              <span>钥匙管理</span>
            </template>
            <el-menu-item index="16-1">完善率({{ bi }})</el-menu-item>
            <el-menu-item index="16-2">借还记录</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="personage_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["token", "city", "userInfo"]),
  },
  data() {
    return {
      urlShow: true,
      activeIndex: "0",
      // userInfo:{},//
      bi:null,
      navList: [
        {
          name: "约看记录",
          iconImage: require("../../assets/image/personage/icon51.png"),
          iconImages: require("../../assets/image/personage/icon52.png"),
          index: "0",
          url: "/personage/aboutSee",
        },
        {
          name: "跟进记录",
          iconImage: require("../../assets/image/personage/icon53.png"),
          iconImages: require("../../assets/image/personage/icon54.png"),
          index: "1",
          children: [
            {
              name: "客源",
              image: "",
              index: "1-1",
              url: "/personage/customer",
            },
            {
              name: "房源",
              image: "",
              index: "1-2",
              url: "/personage/housing",
            },
          ],
        },
        {
          name: "交易记录",
          iconImage: require("../../assets/image/personage/icon55.png"),
          iconImages: require("../../assets/image/personage/icon56.png"),
          index: "2",
          children: [
            {
              name: "线上签约",
              image: "",
              index: "2-1",
              url: "/personage/onSign",
            },
            {
              name: "线下签约",
              image: "",
              index: "2-2",
              url: "/personage/offSign",
            },
          ],
        },
        {
          name: "我的目标",
          iconImage: require("../../assets/image/personage/icon57.png"),
          iconImages: require("../../assets/image/personage/icon58.png"),
          index: "3",
          url: "/personage/achieve",
        },
        {
          name: "审核状态",
          iconImage: require("../../assets/image/personage/icon59.png"),
          iconImages: require("../../assets/image/personage/icon60.png"),
          index: "4",
          children: [
            {
              name: "合同审核",
              image: "",
              index: "4-1",
              url: "/personage/auditMent",
            },
            {
              name: "成交报告",
              image: "",
              index: "4-2",
              url: "/personage/auditReport",
            },
            {
              name: "信息变更",
              image: "",
              index: "4-3",
              url: "/personage/auditMessage",
            },
            {
              name: "删除审核",
              image: "",
              index: "4-4",
              url: "/personage/auditDH",
            },
            {
              name: "添加小区",
              image: "",
              index: "4-5",
              url: "/personage/auditAdd",
            },
            {
              name: "请假审核",
              image: "",
              index: "4-6",
              url: "/personage/auditLeave",
            },
            {
              name: "晋升审核",
              image: "",
              index: "4-7",
              url: "/personage/auditPromote",
            },
            {
              name: "报销审核",
              image: "",
              index: "4-8",
              url: "/personage/auditExp",
            },
          ],
        },
        {
          name: "考勤",
          iconImage: require("../../assets/image/personage/icon61.png"),
          iconImages: require("../../assets/image/personage/icon62.png"),
          index: "5",
          url: "/personage/attendance",
        },
        {
          name: "个人荣誉",
          iconImage: require("../../assets/image/personage/icon63.png"),
          iconImages: require("../../assets/image/personage/icon54.png"),
          index: "6",
          url: "/personage/honor",
        },
        {
          name: "个人违规",
          iconImage: require("../../assets/image/personage/icon65.png"),
          iconImages: require("../../assets/image/personage/icon56.png"),
          index: "7",
          url: "/personage/individual",
        },
        {
          name: "我的业绩",
          iconImage: require("../../assets/image/personage/icon67.png"),
          iconImages: require("../../assets/image/personage/icon68.png"),
          index: "8",
          url: "/personage/myCommission",
        },
        {
          name: "我的关注",
          iconImage: require("../../assets/image/personage/icon69.png"),
          iconImages: require("../../assets/image/personage/icon70.png"),
          index: "9",
          url: "/personage/myAttention",
        },
        {
          name: "黑名单",
          iconImage: require("../../assets/image/personage/icon71.png"),
          iconImages: require("../../assets/image/personage/icon72.png"),
          index: "10",
          url: "/personage/blackList",
        },
        {
          name: "消息",
          iconImage: require("../../assets/image/personage/icon73.png"),
          iconImages: require("../../assets/image/personage/icon54.png"),
          index: "11",
          url: "/personage/information",
        },
        {
          name: "设置",
          iconImage: require("../../assets/image/personage/icon75.png"),
          iconImages: require("../../assets/image/personage/icon76.png"),
          index: "12",
          children: [
            {
              name: "长期锁定记录",
              image: "",
              index: "12-1",
              url: "/personage/setLong",
            },
            {
              name: "房源变更状态",
              image: "",
              index: "12-2",
              url: "/personage/setState",
            },
            {
              name: "修改密码",
              image: "",
              index: "12-3",
              url: "/personage/setPassword",
            },
            {
              name: "员工法则",
              image: "",
              index: "12-4",
              url: "/personage/setRule",
            },
            {
              name: "关于我们",
              image: "",
              index: "12-5",
              url: "/personage/setAbout",
            },
          ],
        },
        {
          name: "团队人员",
          iconImage: require("../../assets/image/personage/icon77.png"),
          iconImages: require("../../assets/image/personage/icon78.png"),
          index: "13",
          url: "/personage/team",
        },
        {
          name: "人事管理",
          iconImage: require("../../assets/image/personage/icon79.png"),
          iconImages: require("../../assets/image/personage/icon80.png"),
          index: "14",
          children: [
            {
              name: "员工管理",
              image: "",
              index: "14-1",
              url: "/personage/Staff",
            },
            {
              name: "面试管理",
              image: "",
              index: "14-2",
              url: "/personage/Interview",
            },
          ],
        },
        {
          name: "合同管理",
          iconImage: require("../../assets/image/personage/icon81.png"),
          iconImages: require("../../assets/image/personage/icon82.png"),
          index: "15",
          children: [
            {
              name: "合同领取",
              image: "",
              index: "15-1",
              url: "/personage/agreeDraw",
            },
            {
              name: "合同上传",
              image: "",
              index: "15-2",
              url: "/personage/agreeCheck",
            },
          ],
        },
        {
          name: "钥匙管理",
          iconImage: require("../../assets/image/personage/icon83.png"),
          iconImages: require("../../assets/image/personage/icon84.png"),
          index: "16",
          children: [
            {
              name: "完善率",
              image: "",
              index: "16-1",
              url: "/personage/keyRate",
            },
            {
              name: "借还记录",
              image: "",
              index: "16-2",
              url: "/personage/keyOther",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      var url = null;
      if (keyPath.length == 2) {
        url =
          this.navList[keyPath[0]].children[keyPath[1].split("-")[1] - 1].url;
      } else {
        url = this.navList[key].url;
      }
      if (url == this.$route.fullPath) return;
      this.$router.push({ path: url });
    },
    getuserinfo () {
      if (this.userInfo.position == 5) {
        this.activeIndex = '14-1'
        if ('/personage/Staff' == this.$route.fullPath) return;
        this.$router.replace({path:'/personage/Staff'})
      }
      // this.$axios.userInfo().then(res => {
      //   this.userInfo = res.data
      //   this.$store.commit('SET_USERINFO', res.data)
      //
      // })
    },
    getkeyList () {
      this.$axios.keyList({
        type:10,
        page:1
      }).then(res=>{
        this.bi = res.data.bi
      })
    }
  },
  created () {
    this.getuserinfo()
    this.getkeyList()
  }
};
</script>

<style lang="less" scoped>
.menu-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.menu-icons {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: none;
}
/deep/ .el-menu-item.is-active .menu-icons {
  display: inherit;
}
/deep/ .el-menu-item.is-active .menu-icon {
  display: none;
}
/deep/ .el-submenu.is-opened .menu-icons {
  display: inherit;
}
/deep/ .el-submenu.is-opened .menu-icon {
  display: none;
}
/deep/ .el-menu-item {
  display: flex;
  align-items: center;
}
/deep/ .el-submenu__title {
  display: flex;
  align-items: center;
}
.personage_nav {
  width: 200px;
  .el-menu-vertical-demo {
    width: 100%;
    border: none;
  }
}
.personage_box {
  align-items: flex-start;
}
.personage_right {
  width: 980px;
}
/deep/ .el-menu-item.is-active {
  background: #ebf2ff;
  color: #3273f6;
  border-left: 4px solid #3273f6;
}
/deep/ .el-submenu.is-opened .el-submenu__title {
  color: #3273f6;
}
/deep/ .el-submenu.is-opened .el-menu-item.is-active {
  background: #ebf2ff;
  color: #3273f6;
  border: none;
}
</style>
