//大事件
<template>
  <div class="business">
    <div class="business_box w-1200 margin-auto">
      <div class="business_tag flexs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>业务规范</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="business_main p-l-40 p-r-40 b-c-f b-r-4">
        <ul class="business_ul">
          <!-- <router-link tag="li" to="/details" v-for="(item,index) in 10" :key="index">
            <div class="business_li_main">
              <div class="business_li_name m-b-10">新有居业务规范流程和详细介绍新有居业务规范流程和详细介绍新有居业务规范流程和详细介绍</div>
              <div class="business_li_time f-z-14 color-6">2020-12-24 15:26</div>
            </div>
            <div class="business_li_go f-z-14">查看详情>></div>
          </router-link> -->
        </ul>
        <div class="paging">
          <el-pagination
            background
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="1000">
          </el-pagination>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {

}
</script>
<style lang="less" scoped>
/deep/ .el-breadcrumb__inner {
  color: #3273F6 !important;
}
/deep/ .is-link {
  color: #666666 !important;
}
.business_ul {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #EBEBEB;
    &:last-child {
      border-bottom: none;
    }
    .business_li_name {
      font-size: 16px;
    }
    .business_li_go {
      color: #FF9600;
      cursor: pointer;
    }
  }
}
</style>