<template>
  <div class="house-page">
    <!--main-position start-->
    <div class="main-position w-1200">
      <router-link to="">业绩统计 </router-link>
      <span class="s color_blue">></span>
      <router-link to="" class="color_blue">新增{{ from.type }}</router-link>
    </div>
    <!--main-position end-->
    <!--house-main start-->
    <div class="house-main w-1400">
      <div class="house-container w-1200">
        <div class="performance_head flex flex-between">
          <span class="span">新增{{ from.type }}</span>
          <div class="per-search flex">
            <i class="el-icon-search icon"></i>
            <div class="search-input">
              <el-input v-model="from.search_name" placeholder="请输入搜索内容"></el-input>
            </div>
            <el-button @click="init">搜索</el-button>
          </div>
        </div>
        <div class="personage_head">
          <div class="personage_head_top">
            <div class="personage_head_txt">类型:</div>
            <ul class="personage_head_ul">
              <li @click="changeType(item.value)" :class="{ active: from.house_type == item.value }" v-for="(item, index) in zuHousingTypeList" :key="index">
                {{ item.label }}
              </li>
            </ul>
          </div>
          <!-- <div class="personage_head_top">
            <div class="personage_head_txt">类型:</div>
            <ul class="personage_head_ul">
              <li
                :class="{ active: index == 0 }"
                v-for="(item, index) in 5"
                :key="index"
              >
                新房
              </li>
            </ul>
          </div> -->
        </div>
        <!--house-table start-->
        <div class="house-table">
          <el-table  @row-click="hrefDetail" :data="list" style="width: 100%" :default-sort="{ prop: 'houseType' }">
            <el-table-column width="134px" label="标题图">
              <template slot-scope="item">
                <div class="house-img">
                  <img class="img" :src="item.row.fang_fengmian_image" />
                  <div class="pos">{{ item.row.fang_level }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200px" label="小区名称">
              <template slot-scope="item">
                <div class="house-name">
                  <div class="name">{{ item.row.xiaoqu_name }}</div>
                  <div class="tags flex-wrap">
                    <!-- <span class="tag orange">{{ item.row.fang_pan_status }}</span> -->
                    <span class="tag" v-for="(em) in item.row.fang_biaoqian" :key="em">{{ em }}</span>
                  </div>
                  <div class="tip">
                    <span class="s">朝向:{{ item.row.fang_chaoxiang }}</span>
                    <span class="s">装修:{{ item.row.fang_xiu }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="fang_xing" label="户型" sortable>
            </el-table-column>
            <el-table-column prop="fang_mianji" label="面积" sortable>
            </el-table-column>
            <el-table-column prop="can_price" label="价格" sortable>
            </el-table-column>
            <el-table-column prop="dian_number" label="点击数量" sortable>
            </el-table-column>
            <el-table-column prop="kan_number" label="7天带看" sortable>
            </el-table-column>
            <el-table-column prop="createtime" label="上架日期" sortable>
            </el-table-column>
            <el-table-column width="150px" prop="updatetime" label="跟进日期" sortable>
            </el-table-column>
            <el-table-column label="维护人">
              <template slot-scope="item">
                <div class="house-person">
                  <div class="t">{{ item.row.weihu_name }}</div>
                  <div class="c-btn m-t-20">
                    <img class="icon" src="../../assets/image/personage/icon13.png" />
                    <span>推</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="paging" v-if="list.length">
            <el-pagination background layout="prev, pager, next, jumper, ->, total" :total="total">
            </el-pagination>
          </div>
        </div>
        <!--house-table end-->
      </div>
    </div>
    <!--house-main end-->
  </div>
</template>

<script>
export default {
  data () {
    return {
      total: 0, //条数
      list: [], //列表
      input: "",
      from: {
        type: "", //1信息方/2钥匙方/3委托方/4图片方/5维护方
        house_type: 2, //房源类型：1新房；2 住宅；3 公寓；4 写字楼；5 商铺；6 别墅
        page: 1,
        search_name: "",// 搜索 姓名/电话
        types: 1,// 1 今日；2 本月【业绩目标->本月目标用到】
        mianji: '',// 面积排序 asc/desc
        can_price: '',// 价格排序 asc/desc
        createtime: '',// 上架时间排序 asc/desc
        updatetime: '',// 跟进时间排序 asc/desc
      },
    };
  },
  methods: {
    //推荐房源
    fangTui (item) {
      this.$axios
        .fangTui({
          type: "出售",
          id: item.id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.init();
        });
    },
    //切换类型
    changeType (index) {
      this.from.house_type = index;
      this.init();
    },
    init () {
      this.from.page = 1;
      this.list = [];
      this.getList();
    },
    //获取列表
    getList () {
      this.$axios.indexXinXi(this.from).then((res) => {
        this.total = res.data.total;
        this.list = res.data.data;
      });
    },
    // changeSort (e) {
    //   if (e.prop == 'fang_mianji' && e.order == 'descending') {
    //     this.form.mianji = 'desc'
    //   } else if (e.prop == 'fang_mianji' && e.order == 'ascending') {
    //     this.form.mianji = 'asc'
    //   } else if (e.prop == 'fang_mianji' && !e.order) {
    //     this.form.mianji = ''
    //   }
    //   if (e.prop == 'can_price' && e.order == 'descending') {
    //     this.form.mianji = 'desc'
    //   } else if (e.prop == 'can_price' && e.order == 'ascending') {
    //     this.form.mianji = 'asc'
    //   } else if (e.prop == 'can_price' && !e.order) {
    //     this.form.mianji = ''
    //   }
    //   if (e.prop == 'createtime' && e.order == 'descending') {
    //     this.form.mianji = 'desc'
    //   } else if (e.prop == 'createtime' && e.order == 'ascending') {
    //     this.form.mianji = 'asc'
    //   } else if (e.prop == 'createtime' && !e.order) {
    //     this.form.mianji = ''
    //   }
    //   if (e.prop == 'updatetime' && e.order == 'descending') {
    //     this.form.mianji = 'desc'
    //   } else if (e.prop == 'updatetime' && e.order == 'ascending') {
    //     this.form.mianji = 'asc'
    //   } else if (e.prop == 'updatetime' && !e.order) {
    //     this.form.mianji = ''
    //   }
    // },
    getType (index) {
      let type = "";
      switch (Number(index)) {
        case 1:
          type = "信息方";
          break;
        case 2:
          type = "钥匙方";
          break;
        case 3:
          type = "委托方";
          break;
        case 4:
          type = "图片方";
          break;
        case 5:
          type = "维护方";
          break;
        default:
          break;
      }
      return type;
    },
    //跳转详情页面
    hrefDetail(row) {
      this.$router.push({
        path: "/housingDetails",
        query: { id: row.id, type: 2 }
      });
    },
  },
  created () {
    this.from.type = this.getType(this.$route.query.type);
    if (this.$route.query.types) {
      this.from.types = this.$route.query.types
    }
    this.getList();
  },
};
</script>

<style scoped lang="less">
.per-search {
  width: 480px;
  border: 2px solid #ebebeb;
  height: 36px;
  position: relative;
  justify-content: flex-start;
  .icon {
    color: #ccc;
    margin: 0 10px;
  }
  .search-input {
    width: 340px;
  }
  /deep/ .search-input .el-input__inner {
    border: none;
    padding: 0;
    height: 32px;
    padding: 0;
    line-height: 32px;
    font-size: 16px;
    text-align: left;
  }
  /deep/ .el-button {
    background: #3273f6;
    width: 100px;
    height: 36px;
    padding: 0;
    border: none;
    border-radius: 0;
    color: #fff;
    position: absolute;
    right: 0px;
    top: -2px;
    font-size: 18px;
  }
}
.performance_head .span::after {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  content: "";
  width: 4px;
  height: 20px;
  background: #3273f6;
  border-radius: 2px;
}
.performance_head .span {
  padding-left: 14px;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}
.main-position {
  display: flex;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
  margin: 0 auto;
  .s {
    margin: 0 3px;
  }
}
.personage_head {
  padding: 10px 0 30px 0px;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.house-main {
  margin: 0 auto 40px auto;
  background: #fff;
}
.house-container {
  padding: 20px 0 30px 0;
  margin: 0 auto;
  /deep/ .el-table th.el-table__cell.is-leaf {
    background: #f5f5f5;
    border: none;
    font-size: 14px;
    color: #333;
    font-weight: normal;
  }
  /deep/ .el-table {
    color: #333;
  }
  /deep/ .el-table td.el-table__cell {
    border-bottom: 1px solid #ebebeb;
  }
  .house-img {
    position: relative;
    .img {
      width: 104px;
      height: 78px;
      border-radius: 4px;
    }
    .pos {
      position: absolute;
      left: 0px;
      top: 0;
      border-radius: 4px 0px 4px 0px;
      background: rgba(0, 0, 0, 0.3);
      line-height: 28px;
      font-size: 12px;
      color: #fff;
      padding: 0 10px;
    }
  }
  .house-person {
    .c-btn {
      width: 58px;
      height: 32px;
      background: #cccccc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .icon {
        width: 14px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }
  .house-name {
    .name {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tags {
      display: flex;
      margin: 6px -3px;
      .tag {
        line-height: 24px;
        background: #f5f5f5;
        border-radius: 3px;
        padding: 0 10px;
        font-size: 12px;
        color: #999;
        margin: 3px;
        white-space: nowrap;
      }
      .tag.orange {
        background: rgba(252, 130, 20, 0.1);
        color: #fc8214;
      }
    }
    .tip {
      color: #999;
      display: flex;
      margin: 0 -5px;
      .s {
        white-space: nowrap;
        margin: 0 5px;
        line-height: 1.6;
      }
    }
  }
}
</style>
