<template>
  <div class="talents">
    <div class="talents_box margin-auto w-1400">
      <div class="talents_main b-c-f">
        <ul class="talents_list" v-if="personnelList.length">
        <li v-for="(item, index) in personnelList" :key="index" @click="lookItem(item)">
          <div class="talents_del_ul flexs" v-if="userInfo.position != 4">
            <div
              class="talents_del_li"
              @click.stop="changeItem(item, ind)"
              v-for="(em, ind) in 2"
              :key="ind"
            >
              <img v-if="ind == 0" src="../../assets/image/talents/edit.png" alt="" />
              <img v-else src="../../assets/image/talents/del.png" alt="" />
            </div>
          </div>
          <div class="talents_list_head">
            <div class="talents_list_img m-r-20">
              <img :src="item.image" alt="" />
            </div>
            <div class="talents_list_main f-1">
              <div class="talents_list_main_head flexs">
                <div class="talents_list_name m-r-20">{{ item.ren }}</div>
                <div class="talents_list_star flexs" v-if="item.position == 4">
                  <el-rate disabled v-model="item.level"></el-rate>
                </div>
              </div>
              <div class="talents_list_con flexs m-t-10 m-b-10 f-1">
                <div class="talents_list_main_phone flexs">
                  <img src="../../assets/image/talents/identity.png" alt="" />
                  <span>{{ item.ren_id }}</span>
                </div>
                <div class="talents_list_main_phone flexs">
                  <img src="../../assets/image/talents/phone.png" alt="" />
                  <span>{{ item.mobile }}</span>
                </div>
              </div>
              <div class="talents_list_address talents_list_main_phone flexs">
                <img src="../../assets/image/talents/shop.png" alt="" />
                <span>{{ item.dian_name }}</span>
              </div>
            </div>
          </div>
          <div class="talents_list_bot m-t-20">
            <template v-for="(item, index) in item.list">
              <div class="talents_list_message flexs" :key="index" v-if="index < 2">
                <span></span>
                <span>{{ item }}</span>
              </div>
            </template>
          </div>
        </li>
      </ul>
      <div class="talents_wu center flex-direction" v-else>
        <img src="../../assets/image/talents/wu.png" alt="">
        <span>暂无人才，请先添加</span>
      </div>
      <div class="paging" v-if="personnelList.length != 0">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="talents_btn center" v-if="userInfo.position != 4">
        <el-button round @click="addTalents">
          <img src="../../assets/image/microlecture/add.png" alt="">
          <span>添加人才</span>
        </el-button>
      </div>
      </div>
      
    </div>
    <!-- 添加人才 S -->
    <add-talents ref="talents" @success="success"></add-talents>
    <!-- 添加人才 E -->
    <!-- 人才详情 S -->
    <!-- <ta-details ref="details" :details="details"></ta-details> -->
    <talents-details ref="details"></talents-details>
    <!-- 人才详情 E -->
    <!-- 编辑人才 S -->
    <edit-talents ref="editTalents" @success="success"></edit-talents>
    <!-- 编辑人才 E -->
  </div>
</template>

<script>
import AddTalents from "../../components/AddTalents.vue";
import EditTalents from '../../components/EditTalents.vue';
import TalentsDetails from '../../components/TalentsDetails.vue';
import { mapGetters } from "vuex";
export default {
  components: { AddTalents, TalentsDetails, EditTalents },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      page: 1, //分页
      total: 0, //总数量
      personnelList: [], //人才库列表
      details:{},//详情
    };
  },
  methods: {
    //查看详情
    lookItem (item) {
      if (this.userInfo.position == 4) return
      this.$refs.details.open(item.id)
    },
    //编辑删除0编辑1删除
    changeItem(item, index) {
      if (index == 0) {
        this.$refs.editTalents.open(item.id)
      } else {
        this.$confirm("确定删除人才信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios
        .personnelDel({
          id:item.id
        })
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.getPersonnelList()
        });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    //分页
    changePage(e) {
      this.page = e;
      this.getPersonnelList();
    },
    success() {
      this.getPersonnelList();
    },
    //添加人才
    addTalents() {
      this.$refs.talents.open();
    },
    //获取人才库
    getPersonnelList() {
      this.$axios
        .personnelList({
          page: this.page,
          pageNum: 9,
          city: this.$store.state.city,
        })
        .then((res) => {
          res.data.data.forEach((item) => {
            let data = [];
            item.text.forEach((em) => {
              let ems = em.substring(1, em.length - 1);
              data.push(
                ems.split("/")[0] +
                  "-" +
                  ems.split("/")[1] +
                  "    ,   " +
                  ems.split("/")[2]
              );
            });
            item.list = data;
          });
          this.personnelList = res.data.data;
          this.total = res.data.total;
        });
    },
  },
  created() {
    this.getPersonnelList();
  },
};
</script>

<style lang="less" scoped>
.talents_box {
  padding: 30px 100px 0 100px;
}
.talents_list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 100px;
  li {
    padding: 20px;
    width: 380px;
    cursor: pointer;
    position: relative;
    margin: 30px 30px 30px 0;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .talents_del_ul {
      top: 15px;
      right: 15px;
      position: absolute;
      .talents_del_li {
        width: 22px;
        height: 22px;
        cursor: pointer;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .talents_list_head {
      display: flex;
    }
    .talents_list_img {
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
    }
    .talents_list_name {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .talents_list_bot {
    padding: 10px;
    background: #f9fbff;
    border-radius: 4px;
    .talents_list_message {
      margin-bottom: 13px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-child {
          width: 6px;
          height: 6px;
          margin-right: 10px;
          background: #3273f6;
          border-radius: 50%;
        }
        &:last-child {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .talents_list_main_phone {
    flex: 1;
    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    span {
      color: #666666;
      font-size: 14px;
    }
  }
}
.talents_main {
  padding-bottom: 200px;
}
.talents_wu {
  padding: 180px 0 100px 0;
  img {
    width: 312px;
    height: 220px;
    margin-bottom: 40px;
  }
}
.talents_btn {
  /deep/ .el-button {
    width: 320px;
    height: 56px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    background: #3273f6;
    border-radius: 28px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 20px;
      display: inline-block;
    }
    span {
      color: #ffffff;
      font-size: 18px;
      line-height: 20px;
      display: inline-block;
    }
  }
}
</style>
