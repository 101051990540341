<template>
  <div class="house-page">
    <!--main-position start-->
    <div class="main-position w-1200">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>业绩目标</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          from.type == 1 ? "今日目标" : "本月目标"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>新增跟进</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--main-position end-->
    <!--house-main start-->
    <div class="house-main w-1400">
      <div class="house-container w-1200">
        <div class="personage_head">
          <div class="personage_head_top">
            <div class="personage_head_txt">类型:</div>
            <ul class="personage_head_ul">
              <li
                @click="changeType(item.value)"
                :class="{ active: item.value == from.fang_type }"
                v-for="(item, index) in housingTypeList"
                :key="index"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <!--house-table start-->
        <div class="house-table">
          <el-table
            :data="tableData"
            style="width: 100%"
            :default-sort="{ prop: 'houseType' }"
          >
            <el-table-column width="134px" label="标题图">
              <template slot-scope="item">
                <div class="house-img">
                  <img
                    class="img"
                    :src="item.row.fang_fengmian_image"
                  />
                  <div class="pos">{{ item.row.fang_level }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200px" label="小区名称">
              <template slot-scope="item">
                <div class="house-name">
                  <div class="name">{{ item.row.name }}</div>
                  <div class="tags">
                    <span class="tag orange">{{ item.row.fang_pan_status }}</span>
                    <span class="tag" v-for="(em,index) in item.row.fang_biaoqian" :key="index">{{ em }}</span>
                  </div>
                  <div class="tip">
                    <span class="s">朝向:{{ item.row.fang_chaoxiang }}</span>
                    <span class="s">装修:{{ item.row.fang_xiu }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="fang_xing" label="户型" sortable>
            </el-table-column>
            <el-table-column prop="fang_mianji" min-width="60" label="面积" sortable>
            </el-table-column>
            <el-table-column prop="can_price" label="价格" sortable>
            </el-table-column>
            <el-table-column prop="dian_number" min-width="90" label="点击数量" sortable>
            </el-table-column>
            <el-table-column prop="look_num" label="7天带看" sortable>
            </el-table-column>
            <el-table-column prop="createtime" min-width="120" label="上架日期" sortable>
            </el-table-column>
            <el-table-column
              width="140px"
              prop="updatetime"
              label="跟进日期"
              sortable
            >
            </el-table-column>
            <el-table-column label="维护人">
              <template slot-scope="item">
                <div class="house-person">
                  <div class="t">{{ item.row.username }}</div>
                  <div @click="fangTui(item.row)" class="c-btn m-t-20" v-if="item.row.fang_level != '展位'">
                    <img
                      class="icon"
                      src="../../assets/image/personage/icon13.png"
                    />
                    <span>推</span>
                  </div>
                </div>
              </template>
              
            </el-table-column>
          </el-table>
          <div class="paging" v-if="tableData.length">
            <el-pagination
              background
              @current-change="changePage"
              layout="prev, pager, next, jumper, ->, total"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!--house-table end-->
      </div>
    </div>
    <!--house-main end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "city"]),
  },
  data() {
    return {
      total: 0, //条数
      tableData: [],
      from:{
        type: 1,
        page: 1,
        city: "",
        fang_type:1,//房源类型 1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
      },
    };
  },
  methods: {
    changePage (e) {
      this.from.page = e
      this.getList()
    },
    //推荐房源
    fangTui (item) {
      this.$axios.fangTui({
        type:'出售',
        id:item.id
      }).then(res=>{
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.init()
      })
    },
    //切换类型
    changeType (type) {
      this.from.fang_type = type
      this.init()
    },
    init () {
      this.from.page = 1
      this.tableData = []
      this.getList()
    },
    getList () {
      this.$axios.indexFangList(this.from).then(res=>{
        this.tableData = res.data.data;
        this.total = res.data.total;
      })
    }
  },
  created () {
    this.from.type = this.$route.query.type;
    this.from.city = this.city;
    this.getList()
  }
};
</script>

<style scoped lang="less">
.main-position {
  display: flex;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
  margin: 0 auto;
  .s {
    margin: 0 3px;
  }
}
.personage_head {
  padding: 10px 0 30px 0px;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.house-main {
  margin: 0 auto 40px auto;
  background: #fff;
}
.house-container {
  padding: 20px 0 30px 0;
  margin: 0 auto;
  /deep/ .el-table th.el-table__cell.is-leaf {
    background: #f5f5f5;
    border: none;
    font-size: 14px;
    color: #333;
    font-weight: normal;
  }
  /deep/ .el-table {
    color: #333;
  }
  /deep/ .el-table td.el-table__cell {
    border-bottom: 1px solid #ebebeb;
  }
  .house-img {
    position: relative;
    .img {
      width: 104px;
      height: 78px;
      border-radius: 4px;
    }
    .pos {
      position: absolute;
      left: 0px;
      top: 0;
      border-radius: 4px 0px 4px 0px;
      background: rgba(0, 0, 0, 0.3);
      line-height: 28px;
      font-size: 12px;
      color: #fff;
      padding: 0 10px;
    }
  }
  .house-person {
    .c-btn {
      width: 58px;
      height: 32px;
      cursor: pointer;
      background: #cccccc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .icon {
        width: 14px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }
  .house-name {
    .name {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tags {
      display: flex;
      margin: 6px -3px;
      flex-wrap: wrap;
      .tag {
        line-height: 24px;
        background: #f5f5f5;
        border-radius: 3px;
        padding: 0 10px;
        font-size: 12px;
        color: #999;
        margin: 3px;
        white-space: nowrap;
      }
      .tag.orange {
        background: rgba(252, 130, 20, 0.1);
        color: #fc8214;
      }
    }
    .tip {
      color: #999;
      display: flex;
      margin: 0 -5px;
      .s {
        white-space: nowrap;
        margin: 0 5px;
        line-height: 1.6;
      }
    }
  }
}
</style>
