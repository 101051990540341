<template>
  <div class="passenger">
    <div class="passenger_box w-1400 margin-auto b-c-f m-t-20 p-r-100 p-l-100">
      <div class="passenger_head p-b-40 p-t-40">
        <div class="passenger_head_ipt">
          <el-input
            placeholder="请输入搜索内容或者联系方式"
            v-model="ruleForm.keywords"
            class="input-with-select"
          >
            <i class="el-icon-search el-input__icon" slot="prefix"> </i>
            <el-button slot="append" @click="init">搜索</el-button>
          </el-input>
          <el-button @click="reset">重置</el-button>
        </div>
        <ul class="passenger_head_ul">
          <li>
            <div class="passenger_head_name">类型</div>
            <div class="passenger_head_ul_right">
              <div
                @click="changeItem(index + 1, 'client_type')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.client_type == index + 1 }"
                v-for="(item, index) in 2"
                :key="index"
              >
                {{ index == 0 ? "私客" : "公客" }}({{
                  index == 0 ? message.private_count : message.public_count
                }})
              </div>
            </div>
            <!-- <div class="passenger_head_name" style="margin-left: 40px">
              联系方式
            </div>
            <div class="flex item-rs">
              <el-input
                clearable
                v-model="ruleForm.yezhu_mobile"
                maxlength="11"
                placeholder="请输入联系方式"
              ></el-input>
              <el-button @click="changePhone">搜索</el-button>
              <el-button @click="reset">重置</el-button>
            </div> -->
          </li>
          <li>
            <div class="passenger_head_name">客户等级</div>
            <div class="passenger_head_ul_right">
              <div
                @click="changeItem('-1', 'booth_type')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.booth_type == -1 }"
              >
                不限
              </div>
              <div
                @click="changeItem(item.value, 'booth_type')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.booth_type == item.value }"
                v-for="(item, index) in classList"
                :key="index"
              >
                {{ item.label }}
              </div>
            </div>
          </li>
          <li>
            <div class="passenger_head_name">装修</div>
            <div class="passenger_head_ul_right">
              <!-- <div
                @click="changeItem('', 'finish_type')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.finish_type == '' }"
              >
                不限
              </div> -->
              <div
                @click="changeItem(item.value, 'finish_type')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.finish_type == item.value }"
                v-for="(item, index) in fitmentList"
                :key="index"
              >
                {{ item.label }}
              </div>
            </div>
          </li>
          <li>
            <div class="passenger_head_name">面积</div>
            <div class="passenger_head_ul_right flex-wrap">
              <div
                @click="changeItem('', 'purpose_area')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.purpose_area == '' }"
              >
                不限
              </div>
              <div
                @click="changeItem(item.value, 'purpose_area')"
                :class="{ active: ruleForm.purpose_area == item.value }"
                class="passenger_head_ul_right_li"
                v-for="(item, index) in mianjiList"
                :key="index"
              >
                {{ item.label }}
              </div>
              <div class="passenger_head_ul_right_li">
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input
                      v-model="ruleForm.mix_fang_mianji"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input
                      v-model="ruleForm.max_fang_mianji"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div class="input-tip m-l-5">m²</div>
                  <el-button
                    @click="
                      getSize(
                        'purpose_area',
                        ruleForm.mix_fang_mianji,
                        ruleForm.max_fang_mianji,
                        'mix_fang_mianji',
                        'max_fang_mianji'
                      )
                    "
                    >提交</el-button
                  >
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="passenger_head_name">价格区间</div>
            <div class="passenger_head_ul_right flex-wrap f-1">
              <div
                @click="changeItem('', 'total_price')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.total_price == '' }"
              >
                不限
              </div>
              <div
                @click="changeItem(item.value, 'total_price')"
                :class="{ active: ruleForm.total_price == item.value }"
                class="passenger_head_ul_right_li"
                v-for="(item, index) in zuPriceList"
                :key="index"
              >
                {{ item.label }}
              </div>
              <div class="passenger_head_ul_right_li">
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input
                      v-model="ruleForm.mix_can_price"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input
                      v-model="ruleForm.max_can_price"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div class="input-tip m-l-5">元</div>
                  <el-button
                    @click="
                      getSize(
                        'total_price',
                        ruleForm.mix_can_price,
                        ruleForm.max_can_price,
                        'mix_can_price',
                        'max_can_price'
                      )
                    "
                    >提交</el-button
                  >
                </div>
            </div>
            </div>
          </li>
          <li>
            <div class="passenger_head_name">居室</div>
            <div class="passenger_head_ul_right">
              <div
                @click="changeItem(-1, 'purpose_house_type')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.purpose_house_type == -1 }"
              >
                不限
              </div>
              <div
                @click="changeItem(item.value, 'purpose_house_type')"
                class="passenger_head_ul_right_li"
                :class="{ active: ruleForm.purpose_house_type == item.value }"
                v-for="(item, index) in floorList"
                :key="index"
              >
                {{ item.label }}
              </div>
              <div class="passenger_head_ul_right_li">
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input
                      v-model="ruleForm.mix_purpose_house_type"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input
                      v-model="ruleForm.max_purpose_house_type"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div class="input-tip m-l-5">室</div>
                  <el-button
                    @click="
                      getSize(
                        'purpose_house_type',
                        ruleForm.mix_purpose_house_type,
                        ruleForm.max_purpose_house_type,
                        'mix_purpose_house_type',
                        'max_purpose_house_type'
                      )
                    "
                    >提交</el-button
                  >
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="passenger_head_name">跟进日期</div>
            <div class="passenger_head_ul_right">
              <el-date-picker
                @change="changeDate"
                v-model="follow_date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </div>
          </li>
          <li>
            <div class="passenger_head_name">录入日期</div>
            <div class="passenger_head_ul_right">
              <el-date-picker
                @change="changeDates"
                v-model="createtime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </div>
          </li>
          <li>
            <div class="passenger_head_name">筛选</div>
            <div class="passenger_head_ul_right flex-wrap">
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'house_type')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.house_type != -1}">
                    房源类型<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.house_type != -1}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.house_type == item.value}"
                      :command="item.value"
                      v-for="(item, index) in getZuHousingTypeList()"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'status')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.status}">
                    客户状态<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.status}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.status == item.value}"
                      :command="item.value"
                      v-for="(item, index) in statusList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'rent_ways')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.rent_ways}">
                    租赁方式<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.rent_ways}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.rent_ways == item.value}"
                      :command="item.value"
                      v-for="(item, index) in rentList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'rent_time')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.rent_time}">
                    租赁时长<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.rent_time}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.rent_time == item.value}"
                      :command="item.value"
                      v-for="(item, index) in leaseList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'orientation')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.orientation}">
                    朝向<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.orientation}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.orientation == item.value}"
                      :command="item.value"
                      v-for="(item, index) in orientationList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'fastest_time')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.fastest_time}">
                    最早入住时间<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.fastest_time}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.fastest_time == item.value}"
                      :command="item.value"
                      v-for="(item, index) in checkTimeList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'pay_way')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.pay_way}">
                    付款方式<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.pay_way}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.pay_way == item.value}"
                      :command="item.value"
                      v-for="(item, index) in payList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'client_label')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.client_label}">
                    标签<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.client_label}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.client_label == item.name}"
                      :command="item.value"
                      v-for="(item, index) in tagList"
                      :key="index"
                      >{{ item.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'new_f')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.new_f}">
                    新增客户<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.new_f}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.new_f == item.value}"
                      :command="item.value"
                      v-for="(item, index) in bambooList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="passenger_head_ul_right-l">
                <el-dropdown @command="changeItem($event, 'fang_gou')">
                  <span class="el-dropdown-link" :class="{blue:ruleForm.fang_gou}">
                    房屋性质<i class="el-icon-arrow-down el-icon--right" :class="{blue:ruleForm.fang_gou}"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    :class="{blue:ruleForm.fang_gou == item.value}"
                      :command="item.value"
                      v-for="(item, index) in buildingList"
                      :key="index"
                      >{{ item.label }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="passenger_table" id="kinds">
        <el-table
          @row-click="changeItems"
          @sort-change="sortChange"
          v-loading="loading"
          :header-cell-style="{ background: '#F5F5F5', color: '#333333' }"
          :data="tableData"
          
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="姓名" width="200px">
            <template slot-scope="item">
              <div>
                <div class="name">{{ item.row.name }}</div>
                <div class="flexs m-t-5">
                  <div class="f-bold" :class="{'color-ff':item.row.status == 5,blue:item.row.status == 3,green:item.row.status == 1}">状态:{{ getStatus(item.row.status) }}</div>
                <div class="tags">
                  <img v-if="item.row.cheng" src="../../assets/image/passenger/cheng.png" alt="">
                  <img v-if="item.row.ji" src="../../assets/image/passenger/ji.png" alt="">
                  <img v-if="item.row.jing" src="../../assets/image/passenger/jing.png" alt="">
                  <img v-if="item.row.ding" src="../../assets/image/passenger/ding.png" alt="">
                  <img v-if="item.row.duan" src="../../assets/image/passenger/duan.png" alt="">
                  <img v-if="item.row.chang" src="../../assets/image/passenger/chang.png" alt="">
                </div>
                </div>
                
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="80px" label="展位">
            <template slot-scope="scope">
              <div class="f-bold color-ff">
                {{ getClass(scope.row.booth_type) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sex" label="性别" width="50">
          </el-table-column>
          <el-table-column
            align="center"
            prop="purpose_house_type"
            width="100"
            label="房型"
          >
          </el-table-column>
          <el-table-column prop="finish_type" align="center" label="装修"></el-table-column>
          <el-table-column label="预算" align="center" prop="min_total_price" sortable>
            <template slot-scope="scope">
              <div class="">
                {{ scope.row.min_total_price }}-{{ scope.row.max_total_price }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="broker_name" align="center" label="经纪人" width="80">
          </el-table-column>
          <el-table-column width="180" align="center" label="意向位置">
            <template slot-scope="scope">
              <div class="">{{ scope.row.purpose_little_area.join(",") }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="look_num"
            width="105"
            sortable
            align="center"
            label="10天带看"
          >
          </el-table-column>
          <el-table-column
            prop="createtime"
            width="100"
            align="center"
            label="添加日期"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="follow_date"
            width="140"
            align="center"
            label="跟进日期"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span :class="{ 'color-ff': scope.row.follow_status == 2 }">{{
                scope.row.follow_date
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paging" v-if="tableData.length">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>

    <!---右侧悬浮 start-->
    <div class="build-fix">
        <div
          class="fix-item li1 active"
          @click="hrefEstate(1)"
        >
          <div class="t">我的任务</div>
        </div>
        
        <div class="fix-item" @click="hrefEstate(4)">
          <div class="">钥匙管理</div>
          <div class="">{{ perfect }}</div>
        </div>
        <div class="fix-item li3" @click="hrefEstate(3)">
          <div class="t">客源录入</div>
        </div>
      </div>
      <!---右侧悬浮 end-->

  </div>
</template>

<script>
import index from "@/common/index";
import { mapGetters } from "vuex";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  mixins: [index],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "region"]),
  },
  data() {
    return {
      perfect:0,//钥匙完整率
      activeName: "",
      follow_date: "", //
      createtime:'',
      regionList: regionData,
      CodeToText: CodeToText,
      ruleForm: {
        page: 1,
        type: 1, //类型：1 求租；2 求购
        city: "",
        keywords: "", //关键词
        follow_date: "",
        createtimes:'',
        purpose_house_type: -1, //居室
        mix_purpose_house_type: "",
        max_purpose_house_type: "",
        status: '', //客户状态： -1 不限；1 未交易；3 有效暂缓；5 无效暂缓；7 我司成交；9 他司成交
        booth_type: -1, //客户等级：-1 不限；1=展位；2=A；3=B；4=C；5=D；
        house_type: -1, //房源类型：-1 不限；1 新房；2 住宅；3 公寓；4 写字楼；5 商铺；6 别墅
        client_type: 1, //公私客类型：1 私客；2 公客
        rent_ways:'',//租赁方式
        rent_time: "",
        orientation: "",
        finish_type: "",
        fastest_time: "",
        pay_way: "",
        client_label: "",
        total_price: "", //区域价格例如：1-2
        purpose_area: "", //面积
        yezhu_mobile: "",
        new_f: "", //新出笋盘
        fang_gou:'',//房屋性质
        total_price_order:'',
        createtime:'',//添加日期排序asc/desc
        follow_dates:'',//跟进日期排序asc/desc
      },
      total: 0,
      tableData: [],
      tagIndex: 0, //标签
      payIndex: 0, //付款方式
      tagList: [
      {
          name: "不限",
          value:'',
          checked: false
        },
        {
          name: "需要车位",
          value:'需要车位',
          checked: false
        },
        {
          name: "需要学区",
          value:'需要学区',
          checked: false
        },
        {
          name: "可带小孩",
          value:'可带小孩',
          checked: false
        },
        {
          name: "拎包入住",
          value:'拎包入住',
          checked: false
        },
        {
          name: "可带宠物",
          value:'可带宠物',
          checked: false
        },
        {
          name: "公司租房",
          value:'公司租房',
          checked: false
        }
      ], //标签
      message: {}, //信息
      loading: true,
      statusList:[],//
    };
  },
  watch: {
    $route: {
      handler(newName) {
        // this.ruleForm.city = this.region;
        this.ruleForm.house_type = this.userInfo.fang_type_privilege === 1 ? 4 : -1;
        if (newName.name == 'Passenger') {
          this.ruleForm.type = newName.query.type;
        this.getPassengerList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.is_colour == 1) {
        return "warning-row";
      }
      return "";
    },
    hrefEstate (index) {
      if (index == 1) {
        this.$router.push({ path: "/personage/achieve" });
      } else if (index == 4) {
        this.$router.push({ path: "/personage/keyRate" });
      } else if (index == 3) {
        this.$router.push({ path: "/addPassenger" });
      }
    },
    sortChange ({column, prop, order}) {
      let type 
      if (prop == 'min_total_price') {
        type = 'total_price_order'
      } else if (prop == 'look_num') {
        type = 'look_num'
      } else if (prop == 'createtime') {
        type = 'createtime'
      } else if (prop == 'follow_date') {
        type = 'follow_dates'
      }
      // this.ruleForm[prop == 'min_total_price' ? 'total_price_order' : prop == 'createtime' ? 'createtime' : 'follow_dates'] = order == 'ascending' ? 'asc' : order == 'descending' ? 'desc' : '';
      this.ruleForm[type] = order == 'ascending' ? 'asc' : order == 'descending' ? 'desc' : '';
      this.init()
    },
    //电话搜索
    changePhone() {
      if (this.ruleForm.yezhu_mobile) {
        if (!this.ruleForm.yezhu_mobile)
          return this.$message({
            message: "请输入联系方式",
            type: "warning",
          });
      }

      this.init();
    },
    //重置
    reset () {
      this.loading = true;
      let client_type = this.ruleForm.client_type
      for (const key in this.ruleForm) {
        this.ruleForm[key] = ''
      }
      this.ruleForm.page = 1;
      this.ruleForm.status = -1;
      this.ruleForm.type = 1;
      this.ruleForm.house_type = -1;
      this.ruleForm.client_type = client_type;
      this.getPassengerList();
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      if (data.length) {
        this.ruleForm.city = data.join("/");
        this.init();
      }
    },
    changePage(e) {
      this.loading = true;
      this.ruleForm.page = e;
      document.getElementById('kinds').scrollIntoView({ behavior: 'smooth' })
      this.getPassengerList();
    },
    changeMore() {
      this.activeName = this.activeName == "" ? "1" : "";
    },
    changeItem(index, type) {
      this.ruleForm[type] = index;
      this.init();
    },
    changeItems(row) {
      this.$router.push({
        path: "/passengerDetails",
        query: { id: row.id, type: this.ruleForm.type },
      });
    },
    changeDate(e) {
      this.ruleForm.follow_date = e ? e.join("-") : '';
      this.init();
    },
    changeDates (e) {
      this.ruleForm.createtimes = e ? e.join("-") : '';
      this.init();
    },
    changeTag(index, type) {
      this[type] = index;
      this.init();
    },
    init() {
      this.ruleForm.page = 1;
      this.loading = true;
      this.tableData = [];
      this.getPassengerList();
    },
    getPassengerList() {
      this.$axios.clientList(this.ruleForm).then((res) => {
        this.loading = false;
        this.total = res.data.list.total;
        this.message = res.data;
        this.tableData = res.data.list.data;
        let data = [
            {
              value:'',
              label:`不限(${ this.ruleForm.client_type == 1 ? res.data.private_count : res.data.public_count})`
            },
            {
              value: 1,
              label: `未交易(${res.data.not_trade_num})`,
            },
            {
              value: 3,
              label: `有效暂缓(${res.data.valid_num})`,
            },
            {
              value: 5,
              label: `无效暂缓(${res.data.not_valid_num})`,
            },
            {
              value: 7,
              label: `我司成交(${res.data.company_num})`,
            },
            {
              value: 9,
              label: `他司成交(${res.data.company_num_t})`
            }
          ]
        this.statusList = this.userInfo.position == 1 || this.userInfo.position == 2 ? data : data.splice(0,3)
      });
    },
    getSize(name, min, max, min_name, max_name) {
      if (!Number(min) && !Number(max)) return;
      if (Number(min) < Number(max)) {
        this.ruleForm[name] = min + "-" + max;
        this.init();
      } else {
        let min_price = min;
        let max_price = max;
        this.ruleForm[max_name] = min_price;
        this.ruleForm[min_name] = max_price;
        this.ruleForm[name] =
          this.ruleForm[min_name] + "-" + this.ruleForm[max_name];
        this.init();
      }
    },
    //获取完善率
    getkeyList() {
      this.$axios.keyList().then((res) => {
        this.perfect = res.data.bi;
      });
    },
  },
  created() {
    // this.ruleForm.city = this.region;
    this.ruleForm.type = this.$route.query.type;
    // this.getPassengerList();
    this.getkeyList();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table .warning-row {
  background: #cccccc;
}
/deep/ .el-table {
    color: #333;
    .cell {
      padding: 0;
    }
  }
.m-l-10 {
  margin-left: 10px;
}
.m-l-5 {
  margin-left: 5px;
}
/deep/ .el-icon-warning-outline {
  color: red;
}
/deep/ .el-range-separator {
  width: auto;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}
/deep/ .el-collapse,
/deep/ .el-collapse-item__wrap {
  border: none;
}
/deep/ .el-collapse-item__header {
  border: none;
  display: none;
}
.more {
  cursor: pointer;
  span {
    font-size: 14px;
    margin-right: 5px;
  }
}
.passenger_head {
  border-bottom: 1px solid #ebebeb;
  .passenger_head_ipt {
  display: flex;
    padding: 0 150px;
    margin-bottom: 40px;
    /deep/ .el-button {
      width: 120px;
      height: 56px;
      color: #ffffff;
      font-size: 18px;
      background: #3273f6;
    }
    /deep/ .el-input__prefix {
      left: 0;
    }
    /deep/ .el-input__inner {
      height: 56px;
      font-size: 18px;
      padding-left: 50px;
      border: 2px solid #ebebeb;
      border-right: none;
    }
    /deep/ .el-icon-search {
      font-size: 22px;
      width: 50px;
    }
  }
  .passenger_head_ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .passenger_head_name {
        width: 130px;
        color: #333333;
        font-size: 16px;
        font-weight: bold;
      }
      .item-rs {
        /deep/ .el-input {
          width: 150px;
          margin-right: 20px;
        }
      }
      .passenger_head_ul_right {
        display: flex;
        .passenger_head_ul_right-l {
          margin-right: 20px;
        }
        .passenger_head_ul_right_li {
          color: #333333;
          font-size: 16px;
          cursor: pointer;
          line-height: 24px;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
          .choice-form {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #666;
            /deep/ .el-button {
              width: 50px;
              text-align: center;
              line-height: 24px;
              padding: 0;
              box-sizing: border-box;
              margin-left: 20px;
            }
            .choice-input {
              width: 80px;
            }
            .choice-icon img {
              width: 12px;
              height: 8px;
            }
            .input-tip {
              font-size: 16px;
              color: #666;
            }
            /deep/ .choice-input .el-input__inner {
              height: 24px;
              line-height: 24px;
              font-size: 14px;
              border-radius: 0px;
            }
            .choice-line {
              width: 20px;
              height: 1px;
              background: #ccc;
              margin: 0px 10px;
            }
            /deep/ .el-checkbox__label {
              font-size: 16px;
            }
          }
        }
        .active {
          color: #3273f6;
          font-weight: bold;
        }
      }
    }
    .li {
      margin-bottom: 0;
    }
  }
}
.name {
  color: #333333;
  font-size: 16px;
}
.tags {
  display: flex;
  margin-top: 6px;
  img {
    width: 28px;
    height: 28px;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.booth {
  color: #3273f6;
  margin-left: 10px;
}
.paging {
  /deep/ .el-pagination__editor {
    margin: 0 10px;
  }
}
.build-fix {
  position: fixed;
  right: 0px;
  z-index: 99999;
  top: 25%;
  text-align: center;
  .fix-item {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #ebebeb;
    background: #fff;
    cursor: pointer;
  }
  .fix-item.li1 {
    background: #fff url("../../assets/image/houseing/house1.png") no-repeat
      center 20px;
  }
  .fix-item.li2 {
    border-top: none;
    border-bottom: none;
    background: #fff url("../../assets/image/houseing/house2.png") no-repeat
      center 20px;
  }
  .fix-item.li3 {
    background: #fff url("../../assets/image/houseing/house3.png") no-repeat
      center 20px;
  }
  .fix-item .t {
    padding-top: 40px;
  }
  .fix-item.active .t {
    color: #fff;
  }
  .fix-item.li1.active {
    background: #3273f6 url("../../assets/image/houseing/house4.png") no-repeat
      center 20px;
  }
  .fix-item.li2.active {
    background: #3273f6 url("../../assets/image/houseing/house5.png") no-repeat
      center 20px;
  }
  .fix-item.li3.active {
    background: #3273f6 url("../../assets/image/houseing/house6.png") no-repeat
      center 20px;
  }
  .actives {
    background: #3273f6;
    color: #fff;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border: none;
    div {
      &:last-child {
        margin-top: 10px;
      }
    }
  }
}
</style>
