<template>
  <div class="talents">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="520"
    >
      <div class="examRule_head flex">
        <span>人才库详情</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="talents_main">
        <div class="talents_head flexs b-b-1">
          <div class="talents_head_img m-r-20">
            <img :src="details.image" alt="" />
          </div>
          <div class="talents_head_main f-1">
            <div class="talents_head_top flexs">
              <div class="talents_name">{{ details.ren }}</div>
              <ul class="talents_star flexs">
                <li v-for="(item, index) in 5" :key="index">
                  <img src="" alt="" />
                </li>
              </ul>
            </div>
            <ul class="talents_head_ul flexs flex-wrap">
              <li>
                <img src="../assets/image/talents/identity.png" alt="">
                <span>{{ details.ren_id }}</span>
              </li>
              <li>
                <img src="../assets/image/talents/phone.png" alt="">
                <span>{{ details.mobile }}</span>
              </li>
              <li>
                <img src="../assets/image/talents/shop.png" alt="">
                <span>{{ details.dian_name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <ul class="talents_list">
          <li v-for="(item,index) in list" :key="index">
            <span></span>
            <span>{{ item }}</span>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list:[],//
      dialogVisible: false,
      details: {}, //详情
    };
  },
  methods: {
    //打开弹窗
    open(id) {
      this.$axios.personnelDetail({ id }).then((res) => {
        let data = [];
        res.data.text.forEach((em) => {
          let ems = em.substring(1, em.length - 1);
          data.push(
            ems.split("/")[0] +
              "-" +
              ems.split("/")[1] +
              "    ,   " +
              ems.split("/")[2]
          );
        });
        this.list = data;
        this.details = res.data;
        this.dialogVisible = true;
      });
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.talents_main {
  padding: 0 10px;
  border-radius: 0 0 8px 8px;
  .talents_head {
    padding: 30px 0;
    .talents_head_img {
      img {
        width: 68px;
        height: 68px;
        border-radius: 50%;
      }
    }
    .talents_name {
      color: #333333;
      font-size: 18px;
      margin-right: 20px;
    }
    .talents_star {
      li {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .talents_head_ul{
      li {
        width: 50%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  
}
.talents_list {
  padding: 30px 0 40px 0;
    li {
      display: flex;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-child {
          width: 6px;
          height: 6px;
          line-height: 16px;
          margin-right: 10px;
          background: #3273f6;
          border-radius: 50%;
        }
        &:last-child {
          color: #666666;
          font-size: 16px;
        }
      }
    }
  }
</style>
