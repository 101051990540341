<template>
  <div class="build-page">
    <div class="build-container w-1200 margin-auto">
      <!--build-head start-->
      <div class="build-head">
        <div class="head-li w-120 m-r-20">
          <el-select @change="changeHousing" v-model="housingTypeIndex" placeholder="请选择">
            <el-option v-for="item in housingType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="head-li w-160">
          <el-select v-model="from.name" filterable remote clearable @change="changePlot" :remote-method="remoteMethod"
            placeholder="请选择">
            <el-option v-for="item in plotList" :key="item.id" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </div>
        <div class="head-line"></div>
        <div class="head-li w-68">
          <el-input v-model="from.dongs" placeholder="楼号"></el-input>
        </div>
        <div class="head-tip">楼号</div>
        <div class="head-line"></div>
        <div class="head-li w-68">
          <el-input v-model="from.danyuans" placeholder="单元"></el-input>
        </div>
        <div class="head-tip">单元</div>
        <div class="head-line"></div>
        <div class="head-li w-68">
          <el-input v-model="from.menpais" placeholder="门牌号"></el-input>
        </div>
        <div class="head-tip m-r-40">室</div>
        <div class="head-li">
          <el-button class="build-head-btn" @click="screen">搜索</el-button>
        </div>
        <div class="head-li">
          <el-button class="build-head-btns" @click="reset">重置</el-button>
        </div>
      </div>
      <!--build-head end-->
      <!--build-choice start-->
      <div class="build-choice w-1200 margin-auto">
        <div class="choice-item">
          <div class="label">区域</div>
          <div class="item-r">
            <el-cascader size="large" clearable :options="regionList" v-model="areaList" placeholder="请选择所在区域"
              @change="handleChangeCity">
            </el-cascader>
          </div>
          <div class="label m-l-20">商圈</div>
          <div class="item-r">
            <el-select @change="changeData" clearable v-model="from.shangquan" placeholder="请选择">
              <el-option v-for="item in shangQuanList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="label m-l-20">联系方式</div>
          <div class="item-r flex item-rs">
            <el-input clearable v-model="from.yezhu_mobile" maxlength="11" placeholder="请输入联系方式"></el-input>
            <el-button @click="changePhone">搜索</el-button>
          </div>
        </div>
        <div class="choice-item">
          <div class="label">智能选房</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeItem('', 'juli')" :class="{ active: from.juli == '' }">
                不限
              </li>
              <li @click="changeItem(item.value, 'juli')" :class="{ active: from.juli == item.value }"
                v-for="(item, index) in juliList" :key="index">
                {{ item.label }}
              </li>
              <li>
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input v-model="from.min_juli" placeholder="请输入"></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input v-model="from.max_juli" placeholder="请输入"></el-input>
                  </div>
                  <div class="input-tip m-l-5">公里</div>
                  <el-button @click="
                    getSize(
                      'juli',
                      from.min_juli,
                      from.max_juli,
                      'min_juli',
                      'max_juli'
                    )
                    ">提交</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="choice-item">
          <div class="label">价格区间</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeItem('', 'can_price')" :class="{ active: from.can_price == '' }">
                不限
              </li>
              <template v-if="from.fang_type == 1">
                <li @click="changeItem(item.value, 'can_price')" :class="{ active: from.can_price == item.value }"
                  v-for="(item, index) in zongPriceList" :key="index">
                  {{ item.label }}
                </li>
              </template>
              <template v-else>
                <li @click="changeItem(item.value, 'can_price')" :class="{ active: from.can_price == item.value }"
                  v-for="(item, index) in zuPriceList" :key="index">
                  {{ item.label }}
                </li>
              </template>

              <li>
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input v-model="from.mix_can_price" placeholder="请输入"></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input v-model="from.max_can_price" placeholder="请输入"></el-input>
                  </div>
                  <el-button @click="
                    getSize(
                      'can_price',
                      from.mix_can_price,
                      from.max_can_price,
                      'mix_can_price',
                      'max_can_price'
                    )
                    ">提交</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">面积区间</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeItem('', 'fang_mianji')" :class="{ active: from.fang_mianji == '' }">
                不限
              </li>
              <li @click="changeItem(item.value, 'fang_mianji')" :class="{ active: from.fang_mianji == item.value }"
                v-for="(item, index) in mianjiList" :key="index">
                {{ item.label }}
              </li>
              <li>
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input v-model="from.mix_fang_mianji" placeholder="请输入"></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input v-model="from.max_fang_mianji" placeholder="请输入"></el-input>
                  </div>
                  <div class="input-tip m-l-5">m²</div>
                  <el-button @click="
                    getSize(
                      'fang_mianji',
                      from.mix_fang_mianji,
                      from.max_fang_mianji,
                      'mix_fang_mianji',
                      'max_fang_mianji'
                    )
                    ">提交</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">房型</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeItem('', 'fang_xing_copy')" :class="{ active: from.fang_xing_copy == '' }">
                不限
              </li>
              <li @click="changeItem(item.value, 'fang_xing_copy')" :class="{ active: from.fang_xing_copy == item.value }"
                v-for="(item, index) in floorList" :key="index">
                {{ item.label }}
              </li>
              <li>
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input v-model="from.mix_fang_xing_copy" placeholder="请输入"></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input v-model="from.max_fang_xing_copy" placeholder="请输入"></el-input>
                  </div>
                  <div class="input-tip m-l-5">居</div>
                  <el-button @click="
                    getSize(
                      'fang_xing_copy',
                      from.mix_fang_xing_copy,
                      from.max_fang_xing_copy,
                      'mix_fang_xing_copy',
                      'max_fang_xing_copy'
                    )
                    ">提交</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">楼层</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeItem('', 'basement')"
                :class="{ active: from.basement == '' && from.xiaoqu_louceng == '' }">
                不限
              </li>
              <li @click="changeItem(item.value, 'basement')" :class="{ active: from.basement == item.value }"
                v-for="(item, index) in levelList" :key="index">
                {{ item.label }}
              </li>
              <li>
                <div class="choice-form">
                  <div class="choice-input">
                    <el-input v-model="from.mix_xiaoqu_louceng" placeholder="请输入"></el-input>
                  </div>
                  <div class="choice-line"></div>
                  <div class="choice-input">
                    <el-input v-model="from.max_xiaoqu_louceng" placeholder="请输入"></el-input>
                  </div>
                  <div class="input-tip m-l-5">层</div>
                  <el-button @click="
                    getSize(
                      'xiaoqu_louceng',
                      from.mix_xiaoqu_louceng,
                      from.max_xiaoqu_louceng,
                      'mix_xiaoqu_louceng',
                      'max_xiaoqu_louceng'
                    )
                    ">提交</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">房源等级</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li @click="changeItem('', 'fang_level')" :class="{ active: from.fang_level == '' }">
                不限
              </li>
              <li @click="changeItem(item.value, 'fang_level')" :class="{ active: from.fang_level == item.value }"
                v-for="item in classList" :key="item.value">
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">跟进日期</div>
          <div class="item-r item-input">
            <el-date-picker @change="changeDate" v-model="updatetime" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy/MM/dd">
            </el-date-picker>
          </div>
        </div>
        <!--choice-item end-->
        <!--choice-item start-->
        <div class="choice-item">
          <div class="label">筛选</div>
          <div class="item-r">
            <ul class="choice-ul">
              <li>
                <!-- @command="changeItem($event,'fang_role')" -->
                <!-- :class="{blue:from.shikan == item.value}" -->
                <el-dropdown @command="changeItem($event, 'shikan')">
                  <span class="el-dropdown-link" :class="{ blue: from.shikan }">
                    实勘<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.shikan }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.shikan == item.value }" :command="item.value"
                      v-for="(item, index) in consolidationList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_role')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_role }">
                    我的角色<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_role }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_role == item.value }" :command="item.value"
                      v-for="(item, index) in roleList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li v-if="from.fang_type == 2">
                <el-dropdown @command="changeItem($event, 'price_type')">
                  <span class="el-dropdown-link" :class="{ blue: from.price_type }">
                    付款方式<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.price_type }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.price_type == item.value }" :command="item.value"
                      v-for="(item, index) in payList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_yongtu')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_yongtu }">
                    房屋用途<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_yongtu }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_yongtu == item.value }" :command="item.value"
                      v-for="(item, index) in houseUse" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_xiu')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_xiu }">
                    装修<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_xiu }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_xiu == item.value }" :command="item.value"
                      v-for="(item, index) in fitmentList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>

              <li>
                <el-dropdown @command="changeItem($event, 'fang_gou')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_gou }">
                    房屋性质<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_gou }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_gou == item.value }" :command="item.value"
                      v-for="(item, index) in buildingList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_ling')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_ling }">
                    房龄<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_ling }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_ling == item.value }" :command="item.value"
                      v-for="(item, index) in houseAgeList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_xiu_time')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_xiu_time }">
                    装修时间<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_xiu_time }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_xiu_time == item.value }" :command="item.value"
                      v-for="(item, index) in fitmentTimeList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_kan')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_kan }">
                    看房方式<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_kan }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_kan == item.value }" :command="item.value"
                      v-for="(item, index) in lookWayList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_zhu')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_zhu }">
                    最早入住时间<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_zhu }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_zhu == item.value }" :command="item.value"
                      v-for="(item, index) in checkTimeList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_type')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_type && from.fang_type != -1 }">
                    房屋类型<i class="el-icon-arrow-down el-icon--right"
                      :class="{ blue: from.fang_type && from.fang_type != -1 }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_type == item.value }" :command="item.value"
                      v-for="(item, index) in getZuHousingTypeList()" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'y_status')">
                  <span class="el-dropdown-link" :class="{ blue: from.y_status }">
                    房源状态<i class="el-icon-arrow-down el-icon--right" :class="{ blue: from.y_status }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.y_status == item.value }" :command="item.value"
                      v-for="(item, index) in fangStatus" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>

              <li>
                <el-dropdown @command="changeItem($event, 'fang_chaoxiang')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_chaoxiang }">朝向<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_chaoxiang }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_chaoxiang == item.value }" :command="item.value"
                      v-for="(item, index) in orientationList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'fang_biaoqian')">
                  <span class="el-dropdown-link" :class="{ blue: from.fang_biaoqian }">房屋标签<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.fang_biaoqian }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.fang_biaoqian == item.value }" :command="item"
                      v-for="(item, index) in tagList" :key="index">{{ item }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'img_type')">
                  <span class="el-dropdown-link" :class="{ blue: from.img_type }">图片视频<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.img_type }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.img_type == item.value }" :command="item.value"
                      v-for="(item, index) in imageVideoList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'zu_type')">
                  <span class="el-dropdown-link" :class="{ blue: from.zu_type }">租赁方式<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.zu_type }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.zu_type == item.value }" :command="item.value"
                      v-for="(item, index) in rentList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'zu_types')">
                  <span class="el-dropdown-link" :class="{ blue: from.zu_types }">出租时长<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.zu_types }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.zu_types == item.value }" :command="item.value"
                      v-for="(item, index) in rentStateList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'che_que')">
                  <span class="el-dropdown-link" :class="{ blue: from.che_que }">车位<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.che_que }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.che_que == item.value }" :command="item.value"
                      v-for="(item, index) in whetherList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'new_f')">
                  <span class="el-dropdown-link" :class="{ blue: from.new_f }">新上房源<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.new_f }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.new_f == item.value }" :command="item.value"
                      v-for="(item, index) in bambooList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'img_bu')">
                  <span class="el-dropdown-link" :class="{ blue: from.img_bu }">核验布置<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.img_bu }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.img_bu == item.value }" :command="item.value"
                      v-for="(item, index) in imageLayout" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'yezhu_prc')">
                  <span class="el-dropdown-link" :class="{ blue: from.yezhu_prc }">业主费用<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.yezhu_prc }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.yezhu_prc == item.value }" :command="item.value"
                      v-for="(item, index) in ownerPrice" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'is_qi')">
                  <span class="el-dropdown-link" :class="{ blue: from.is_qi }">是否到期<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.is_qi }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.is_qi == item.value }" :command="item.value"
                      v-for="(item, index) in whetherList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown @command="changeItem($event, 'is_kan')">
                  <span class="el-dropdown-link" :class="{ blue: from.is_kan }">能否看房<i
                      class="el-icon-arrow-down el-icon--right" :class="{ blue: from.is_kan }"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :class="{ blue: from.is_kan == item.value }" :command="item.value"
                      v-for="(item, index) in whetherList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </div>
        <!--choice-item end-->
      </div>
      <!--build-choice end-->
      <!--house-container start-->
      <div class="house-container">
        <!--house-table start-->
        <div class="house-table" id="kinds">
          <el-table @row-click="hrefDetail" @sort-change="sortChange" :data="tableData" style="width: 100%"
            v-loading="loading" :row-class-name="tableRowClassName" :default-sort="{ prop: 'houseType' }">
            <el-table-column align="center" width="104px" label="标题图">
              <template slot-scope="item">
                <div class="house-img">
                  <img v-if="item.row.fang_fengmian_image" class="img" :src="item.row.fang_fengmian_image" />
                  <div class="pos">{{ item.row.fang_level }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="180px" label="小区名称">
              <template slot-scope="item">
                <div class="house-name">
                  <div class="name">{{ item.row.name }}</div>
                  <div class="tags">
                    <span class="tag orange" v-if="from.type == '出售'">{{
                      item.row.fang_pan_status
                    }}</span>
                    <img v-if="item.row.is_new == 1" class="tag-icon" src="../../assets/image/houseing/house10.png" />
                    <img v-if="item.row.yaoshi_type && item.row.yaoshi_type != '无'" class="tag-icon"
                      src="../../assets/image/houseing/house11.png" />
                    <img v-if="item.row.ches" class="tag-icon" src="../../assets/image/houseing/house12.png" />
                    <img v-if="item.row.is_image == 1" class="tag-icon" src="../../assets/image/houseing/house13.png" />
                    <img v-if="item.row.cheng" class="tag-icon" src="../../assets/image/passenger/cheng.png" alt="">
                    <img v-if="item.row.ji" class="tag-icon" src="../../assets/image/passenger/ji.png" alt="">
                    <img v-if="item.row.jing" class="tag-icon" src="../../assets/image/passenger/jing.png" alt="">
                    <img v-if="item.row.ding" class="tag-icon" src="../../assets/image/passenger/ding.png" alt="">
                    <img v-if="item.row.sun" class="tag-icon" src="../../assets/image/passenger/sun.png" alt="">
                    <img v-if="item.row.pin" class="tag-icon" src="../../assets/image/passenger/pin.png" alt="">

                  </div>
                  <div class="tip">
                    <span class="s f-bold"
                      :class="{ 'color-ff': item.row.fang_status == 5, blue: item.row.fang_status == 3, green: item.row.fang_status == 1 }">状态:{{
                        getStatus(item.row.fang_status) }}</span>
                    <span class="s">装修:{{ item.row.fang_xiu }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" width="80px" label="展位">
              <template slot-scope="item">
                <div class="f-bold color-ff">{{ item.row.fang_level }}</div>
              </template>
            </el-table-column>
            <el-table-column width="100px" align="center" label="楼栋号">
              <template slot-scope="item">
                <!-- <div>{{ item.row.fang_status != 1 ? item.row.xiaoqu_loudong
.join('-') : '' }}</div> -->
                <div>{{ item.row.xiaoqu_loudong
                  .join('-') }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="fang_xing_copy" label="户型" sortable>
              <template slot-scope="item">
                <div>
                  {{ item.row.fang_xing_copy == 10000 ? '' : item.row.fang_xing_copy
                  }}{{ item.row.fang_xing_copy == 10000 ? '标间' : item.row.fang_xing_copy ? "居" : "" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="fang_mianji" label="面积" sortable>
              <template slot-scope="item">
                <div>
                  {{ item.row.fang_mianji
                  }}{{ item.row.fang_mianji ? "m²" : "" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="can_price" label="价格" sortable>
            </el-table-column>
            <el-table-column align="center" width="100px" prop="dian_number" label="点击数量" sortable>
            </el-table-column>
            <el-table-column align="center" prop="houseSee" label="7天带看">
            </el-table-column>
            <el-table-column align="center" prop="createtime" label="上架日期" sortable="custom">
            </el-table-column>
            <el-table-column width="150px" prop="updatetime" label="跟进日期" sortable="custom" align="center">
              <template slot-scope="item">
                <div :class="{ 'color-ff': item.row.follow_status == 2 }">
                  {{ item.row.updatetime }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="维护人">
              <template slot-scope="item">
                <div class="house-person">
                  <div class="t">{{ item.row.weihu_name }}</div>
                  <div v-if="userInfo.position != 4" @click.stop="changeTui(item.row)" class="c-btn m-t-20"
                    :class="{ red: item.row.fang_level != '展位' }">
                    <img class="icon" src="../../assets/image/personage/icon13.png" />
                    <span>推</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="paging" v-if="tableData.length">
            <el-pagination background :current-page="from.page" @current-change="changePage"
              layout="prev, pager, next, jumper, ->, total" :total="total" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </div>
        </div>
        <!--house-table end-->
      </div>
      <!--house-container end-->
      <!---右侧悬浮 start-->
      <div class="build-fix">
        <div class="fix-item li1" :class="{ active: keyIndex == 0 }" @click="hrefEstate(1)">
          <div class="t">我的任务</div>
        </div>

        <div class="fix-item" @click="hrefEstate(4)">
          <div class="">钥匙管理</div>
          <div class="">{{ perfect }}</div>
        </div>
        <div class="fix-item li3" @click="hrefEstate(3)">
          <div class="t">房源录入</div>
        </div>
      </div>
      <!---右侧悬浮 end-->
    </div>
    <!-- 选择房源 S -->
    <select-housing @select="selectHousing" ref="selectHousing"></select-housing>
    <!-- 选择房源 E -->
  </div>
</template>

<script>
import index from "@/common/index";
import { mapGetters } from "vuex";
import { regionData, CodeToText } from "element-china-area-data";
import SelectHousing from "@/components/SelectHousing.vue";


export default {
  components: { SelectHousing },
  mixins: [index],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "region", "city"]),
  },
  data() {
    return {
      total: 0, //条数
      tableData: [],
      regionList: regionData,
      CodeToText: CodeToText,
      housingTypeIndex: "/housing?type=2", //
      checkedCities: [], //
      from: {
        name: "", //小区名称
        y_status: 1, //状态搜索 1未交易3有效暂缓5无效暂缓7我司成交9他司成交
        xiaoqus: "",
        dongs: "", //小区楼栋号
        danyuans: "", //小区楼栋号
        menpais: "", //小区门牌号
        type: "出租",
        page: 1,
        juli: "", //智能选房 （区间用-隔开）
        min_juli: "",
        max_juli: "",
        fang_role: "", //
        shikan: '',//实勘
        fang_level: "", //
        fang_type: -1, //1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
        price_type: "", //付款方式：1=全款2=按揭付款
        fang_pan_status: "", //状态搜索
        chanquan_ren: "", //产权搜索
        fang_xiu: "", //装修
        fang_ling: "", //房龄
        can_price: "", //价格区间 （区间用-隔开）
        mix_can_price: "",
        max_can_price: "",
        fang_mianji: "", //面积区间 （区间用-隔开）
        mix_fang_mianji: "",
        max_fang_mianji: "",
        fang_xing_copy: "", //房型 （区间用-隔开）
        mix_fang_xing_copy: "",
        max_fang_xing_copy: "",
        basement: '',//
        xiaoqu_louceng: "", //楼层 （区间用-隔开）
        mix_xiaoqu_louceng: "",
        max_xiaoqu_louceng: "",
        fang_yongtu: "", //房屋用途
        fang_kan: "", //看房方式
        fang_zhu: "", //最早入住时间
        fang_gou: "", //房屋性质
        fang_xiu_time: "", //装修时间
        fang_biaoqian: "", //房屋标签
        fang_kaipan_ci: "", //开盘次数
        fang_kaipan_time: "", //开盘时间
        mix_fang_kaipan_time: "",
        max_fang_kaipan_time: "",
        fang_chaoxiang: "", //朝向
        shangquan: "", //商圈
        city: "",
        fang_jingwei: this.$store.state.location.lng + "," + this.$store.state.location.lat,
        img_type: "", //有无图片视频
        zu_types: "", //租赁状态
        zu_type: "",
        yezhu_mobile: "",//业主电话
        new_f: "",//新出笋盘
        img_bu: '',//核验布置
        yezhu_prc: '',//业主费用
        yaoshi_type: '',//钥匙情况
        is_kan: '',//能否看房
        is_qi: '',//是否到期
        updatetime: '',//跟进日期
        createtime_pai: '',
        updatetime_pai: '',
        can_price_pai: '',
        dian_number_pai: '',
        fang_xing_copys: '',
        fang_mianji_pai: '',
        pageNum: 10
      },
      updatetime: '',//跟进日期
      perfect: 0, //完善率
      keyIndex: 0,
      input: "",
      value: "",
      options: [],
      tagList: [], //
      shangQuanList: [], //商圈列表
      areaList: [],
      fangStatus: [], //房源状态
      plotList: [],
      loading: true
    };
  },
  watch: {
    $route: {
      handler(newName) {
        // this.from.type = newName.query.type == 1 ? "出售" : "出租";
        // this.from.fang_type = newName.query.type == 1 ? 1 : 2
        // this.from.city = this.city;
        this.from.fang_type = this.userInfo.fang_type_privilege === 1 ? 4 : -1
        if (newName.name == 'Housing') {
          this.getHousingList();
          this.getTagList(newName.query.type);
        }
      },
      immediate: true,
    },
  },
  methods: {
    //远程排序跟进日期
    sortChange({ column, prop, order }) {
      let type
      if (prop == 'fang_xing_copy') {
        type = 'fang_xing_copys'
      } else if (prop == 'fang_mianji') {
        type = 'fang_mianji_pai'
      } else if (prop == 'can_price') {
        type = 'can_price_pai'
      } else if (prop == 'dian_number') {
        type = 'dian_number_pai'
      } else if (prop == 'createtime') {
        type = 'createtime_pai'
      } else if (prop == 'updatetime') {
        type = 'updatetime_pai'
      }
      // this.from[prop == 'createtime' ? 'createtime_pai' : prop == 'can_price' ? 'can_price_pai' : 'updatetime_pai'] = order == 'ascending' ? 'asc' : order == 'descending' ? 'desc' : '';
      this.from[type] = order == 'ascending' ? 'asc' : order == 'descending' ? 'desc' : '';
      this.changeData();
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      //  当滚动超过 450 时，实现吸顶效果

      if (scrollTop > 450) {
        this.showNav = false
      } else {
        this.showNav = true
      }
    },
    changeDate(e) {
      if (e && e.length) {
        this.from.page = 1
        this.loading = true
        this.from.updatetime = e.join(',')
        this.getHousingList();
      }

    },
    //选择房源
    selectHousing(index) {
      if (index == 0) {
        this.$router.push({ path: "/addHousing", query: { type: 1 } });
      } else if (index == 1) {
        this.$router.push({ path: "/addRentHousing", query: { type: 2 } });
      } else if (index == 2) {
        console.log("000");
      } else if (index == 4) {
        this.$refs.applyPlot.open();
      } else if (index == 5) {
        this.$router.push({ path: "/estate?type=1" });
      }
    },
    remoteMethod(e) {
      this.$axios
        .xiaoQuList({
          letter: e,
          pageNum: 10000,
        })
        .then((res) => {
          this.plotList = this.getFTPrivilegeXiaoQuList(res.data.data);
        });
    },
    tableRowClassName({ row }) {
      if (row.is_colour == 1) {
        return "warning-row";
      }
      return "";
    },
    //推房源
    changeTui(item) {
      if (item.fang_level != "展位") {
        this.$axios
          .fangTui({
            id: item.id,
            type: item.fang_type == 1 ? "出售" : "出租",
          })
          .then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.from.page = 1;
            this.getHousingList();
          });
      }
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      this.from.shangquan = ''
      this.from.city = data.join("/");
      this.changeData();
      if (this.from.city) {
        this.getshangQuan();
      }
    },
    //电话搜索
    changePhone() {
      if (this.from.yezhu_mobile) {
        if (!this.from.yezhu_mobile) return this.$message({
          message: "请输入联系方式",
          type: "warning",
        });
      }

      this.changeData();
    },
    screen() {
      this.loading = true
      this.from.page = 1;
      this.getfangStatusNum()
      this.getHousingList();
    },
    //重置
    reset() {
      this.loading = true
      this.from.page = 1;
      for (const key in this.from) {
        this.from[key] = ''
      }
      this.from.fang_type = -1
      this.from.type = '出租'
      this.from.fang_jingwei = this.$store.state.location.lng + "," + this.$store.state.location.lat
      this.getfangStatusNum()
      this.getHousingList();
    },
    getSize(name, min, max, min_name, max_name) {
      if (!Number(min) && !Number(max))
        return this.$message({
          message: "搜索有误",
          type: "warning",
        });
      if (Number(min) < Number(max)) {
        this.from[name] = min + "-" + max;
        this.changeData();
      } else {
        let min_price = min;
        let max_price = max;
        this.from[max_name] = min_price;
        this.from[min_name] = max_price;
        this.from[name] = this.from[min_name] + "-" + this.from[max_name];
        this.changeData();
      }
    },
    //切换类型
    changeHousing(e) {
      if (e == this.$route.fullPath) return;
      this.$router.push({ path: e });
    },
    handleCheckedCitiesChange(e) {
      this.from.fang_biaoqian = e.join(",");
      this.changeData();
    },
    //切换
    changeItem(index, type) {
      this.from[type] = index;
      if (type == "fang_biaoqian") [(this.checkedCities = [])];
      if (type == 'basement') {
        this.from.xiaoqu_louceng = '';
        this.from.mix_xiaoqu_louceng = '';
        this.from.max_xiaoqu_louceng = '';
      }
      this.changeData();
    },
    //改变数据
    changeData() {
      this.loading = true
      this.from.page = 1;
      this.tableData = [];
      this.getHousingList();
    },
    //切换分页
    changePage(e) {
      this.loading = true
      this.from.page = e;
      document.getElementById('kinds').scrollIntoView({ behavior: 'smooth' })
      this.getHousingList();
    },
    //获取房源列表
    getHousingList() {
      this.$axios.fangList(this.from).then((res) => {
        this.loading = false
        res.data.data.forEach(item => {
          item.fang_fengmian_image = item.fang_fengmian_image == '0' ? '' : item.fang_fengmian_image;
        })
        this.tableData = this.getFTPrivilegeXiaoQuList(res.data.data);
        this.total = res.data.total;
      });
    },
    //切换小区
    changePlot() {
      this.from.dongs = ''
      this.from.danyuans = ''
      this.from.menpais = ''
    },
    //跳转小区页面
    hrefEstate(index) {
      this.keyIndex = index;
      if (
        this.userInfo.position != 1 &&
        this.userInfo.position != 2 &&
        index == 2
      ) {
        this.$router.push({ path: "/personage/keyRate" });
        return;
      }
      if (index == 1) {
        this.$router.push({ path: "/personage/achieve" });
      } else if (index == 4) {
        this.$router.push({ path: "/personage/keyRate" });
      } else if (index == 3) {
        this.$refs.selectHousing.dialogVisible = true;
        // this.$router.push({ path: "/addHousing?type=1" });
      }
    },
    //跳转详情页面
    hrefDetail(row) {
      this.$router.push({
        path: "/housingDetails",
        query: { id: row.id, type: this.$route.query.type },
      });
    },
    //获取标签

    getTagList(type) {
      this.$axios.fangSpans({ type }).then((res) => {
        this.tagList = res.data;
      });
    },
    getshangQuan() {
      this.$axios.shangQuan({ city: this.from.city }).then((res) => {
        this.shangQuanList = res.data;
      });
    },
    //获取完善率
    getkeyList() {
      this.$axios.keyList().then((res) => {
        this.perfect = res.data.bi;
      });
    },
    //获取数量
    getfangStatusNum() {
      this.$axios.fangStatusNum({ name: this.from.name }).then(res => {
        let data = [
          {
            value: '',
            label: "不限",
          },
          {
            value: 1,
            label: "未交易",
          },
          {
            value: 3,
            label: "有效暂缓",
          },
          {
            value: 5,
            label: "无效暂缓",
          },
          {
            value: 7,
            label: "我司成交",
          },
          {
            value: 9,
            label: "他司成交",
          }
        ]
        res.data.forEach((element, index) => {
          data[index].label = `${data[index].label}(${element})`
        });
        this.fangStatus = data
      })
    }
  },
  created() {
    // this.from.fang_type = this.$route.query.type == 1 ? 1 : 2
    // this.from.type = this.$route.query.type == 1 ? "出售" : "出租";

    // this.getHousingList();
    this.remoteMethod("");
    // this.getTagList(this.$route.query.type);
    this.getkeyList();
    this.getfangStatusNum()

  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table .warning-row {
  background: #cccccc;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-5 {
  margin-left: 5px;
}

.build-page {
  background: #fff;
}

.build-container {
  padding-top: 35px;
}

.build-head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 35px;
  max-width: 1200px;
  margin: 0 auto;

  .w-120 {
    width: 120px;
  }

  .w-160 {
    width: 160px;
  }

  .w-68 {
    width: 68px;
  }

  .head-line {
    width: 20px;
    height: 2px;
    background: #ccc;
    margin: 0 6px;
  }

  .head-tip {
    font-size: 18px;
    margin-left: 5px;
  }

  /deep/ .el-input__inner {
    height: 48px;
    line-height: 48px;
    font-size: 18px;
  }

  .build-head-btn {
    width: 100px;
    height: 48px;
    background: #3273f6;
    font-size: 18px;
    color: #fff;
  }

  .build-head-btns {
    width: 100px;
    height: 48px;
    font-size: 18px;
    margin-left: 20px;
    // border: 1px solid #3273f6;
  }
}

.build-choice {
  .choice-item {
    display: flex;
    margin: 5px 0px;
  }

  .label {
    width: 100px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    min-width: 100px;
    max-width: 100%;
  }

  .item-r {
    display: flex;
    flex-wrap: wrap;

  }

  .item-input {
    /deep/ .el-input__inner {
      width: 450px;
    }
  }

  .item-rs {
    /deep/ .el-input {
      width: 150px;
      margin-right: 20px;
    }
  }

  .choice-ul {
    display: flex;
    flex-wrap: wrap;
  }

  .choice-ul li {
    margin-right: 20px;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .choice-ul li span {
    line-height: 32px;
  }

  .choice-ul li.active {
    color: #3273f6;
  }

  .choice-form {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #666;

    /deep/ .el-button {
      width: 50px;
      text-align: center;
      line-height: 24px;
      padding: 0;
      box-sizing: border-box;
      margin-left: 20px;
    }
  }

  .choice-input {
    width: 80px;
  }

  .choice-icon img {
    width: 12px;
    height: 8px;
  }

  .input-tip {
    font-size: 16px;
    color: #666;
  }

  /deep/ .choice-input .el-input__inner {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    border-radius: 0px;
  }

  .choice-line {
    width: 20px;
    height: 1px;
    background: #ccc;
    margin: 0px 10px;
  }

  /deep/ .el-checkbox__label {
    font-size: 16px;
  }
}

.house-container {
  padding-top: 20px;
  margin: 0 auto;

  /deep/ .el-table th.el-table__cell.is-leaf {
    background: #f5f5f5;
    border: none;
    font-size: 14px;
    color: #333;
    font-weight: normal;
  }

  /deep/ .el-table {
    color: #333;

    .cell {
      padding: 0;
    }
  }

  /deep/ .el-table td.el-table__cell {
    border-bottom: 1px solid #ebebeb;
  }

  .house-img {
    width: 90px;
    height: 78px;
    position: relative;

    .img {
      width: 90px;
      height: 78px;
      border-radius: 4px;
    }

    .pos {
      position: absolute;
      left: 0px;
      top: 0;
      border-radius: 4px 0px 4px 0px;
      background: rgba(0, 0, 0, 0.3);
      line-height: 28px;
      font-size: 12px;
      color: #fff;
      padding: 0 10px;
    }
  }

  .house-person {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .c-btn {
      width: 58px;
      height: 32px;
      cursor: pointer;
      background: #cccccc;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      .icon {
        width: 14px;
        height: 16px;
        margin-right: 10px;
      }
    }

    .red {
      background: #ff4e4e;
    }
  }

  .house-name {
    .name {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tags {
      display: flex;
      margin: 6px 0px;
      align-items: center;
      flex-wrap: wrap;

      .tag {
        line-height: 24px;
        background: #f5f5f5;
        border-radius: 3px;
        padding: 0 10px;
        font-size: 12px;
        color: #999;
        margin: 3px;
        white-space: nowrap;
      }

      .tag.orange {
        background: rgba(252, 130, 20, 0.1);
        color: #fc8214;
      }

      .tag-icon {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }
    }

    .tip {
      color: #999;
      display: flex;
      margin: 0 -5px;

      .s {
        white-space: nowrap;
        margin: 0 5px;
        line-height: 1.6;
      }
    }
  }
}

.build-fix {
  position: fixed;
  right: 0px;
  z-index: 99999;
  top: 25%;
  text-align: center;

  .fix-item {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #ebebeb;
    background: #fff;
    cursor: pointer;
  }

  .fix-item.li1 {
    background: #fff url("../../assets/image/houseing/house1.png") no-repeat center 20px;
  }

  .fix-item.li2 {
    border-top: none;
    border-bottom: none;
    background: #fff url("../../assets/image/houseing/house2.png") no-repeat center 20px;
  }

  .fix-item.li3 {
    background: #fff url("../../assets/image/houseing/house3.png") no-repeat center 20px;
  }

  .fix-item .t {
    padding-top: 40px;
  }

  .fix-item.active .t {
    color: #fff;
  }

  .fix-item.li1.active {
    background: #3273f6 url("../../assets/image/houseing/house4.png") no-repeat center 20px;
  }

  .fix-item.li2.active {
    background: #3273f6 url("../../assets/image/houseing/house5.png") no-repeat center 20px;
  }

  .fix-item.li3.active {
    background: #3273f6 url("../../assets/image/houseing/house6.png") no-repeat center 20px;
  }

  .actives {
    background: #3273f6;
    color: #fff;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border: none;

    div {
      &:last-child {
        margin-top: 10px;
      }
    }
  }
}

.paging {
  /deep/ .el-pagination__editor {
    margin: 0 20px;
  }
}
</style>
