<template>
  <div class="p-page">
    <div class="per-container w-1200">
      <div class="per-inner">
        <!--企业前三名 per-box start-->
        <div class="per-box">
          <div class="per-con">
            <div class="performance_head flex flex-between">
              <span class="span">企业前三名</span>
              <div class="per-date">
                <el-date-picker
                  @change="getqiYeRanking"
                  v-model="time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </div>
            <!--per-one start-->
            <div class="per-one m-t-30" v-if="list.length">
              <div class="li li1">
                <template v-if="list[1]">
                  <div class="h-icon">2</div>
                    <div class="h-img">
                    <img class="img" :src="list[1].image">
                    </div>
                    <div class="h-name">{{ list[1].name }}</div>
                    <div class="per-rate">
                      <img
                        v-for="(item,index) in list[1].level"
                        :key="index"
                        class="star-icon"
                        src="../../assets/image/performance/star.png"
                      />
                    </div>
                </template>
                
              </div>
              <div class="li li2">
                <template v-if="list[0]">
                  <div class="h-icon">1</div>
                    <div class="h-img">
                      <img class="img" :src="list[0].image">
                    </div>
                    <div class="h-name">{{ list[0].name }}</div>
                    <div class="per-rate">
                      <img
                        v-for="(item,index) in list[0].level"
                        :key="index"
                        class="star-icon"
                        src="../../assets/image/performance/star.png"
                      />
                    </div>
                </template>
                
              </div>
              <div class="li li3">
                <template v-if="list[2]">
                  <div class="h-icon">3</div>
                    <div class="h-img">
                      <img class="img" :src="list[2].image">
                    </div>
                    <div class="h-name">{{ list[2].name }}</div>
                    <div class="per-rate">
                    <img
                        v-for="(item,index) in list[2].level"
                        :key="index"
                        class="star-icon"
                        src="../../assets/image/performance/star.png"
                      />
                    </div>
                </template>
                
              </div>
            </div>
            <!--per-one end-->
          </div>
        </div>
        <!--per-box end-->
        <!--每日统计 per-box start-->
        <div class="per-box">
          <div class="per-con">
            <div class="performance_head flex flex-between">
              <span class="span">每日统计</span>
              <div class="per-date">
                <el-date-picker
                  v-model="totay"
                  type="date"
                  :clearable="false"
                  :editable="false"
                  value-format="timestamp"
                  placeholder="选择日期"
                  @change="getTargetInfo"
                >
                </el-date-picker>
              </div>
            </div>
            <!--per-two start-->
            <ul class="per-two m-t-30">
              <li>
                <router-link to="/perBuyer">
                  <div class="num">{{ statistics.clienf_number }}</div>
                  <div class="t">新增客户</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perHouse">
                  <div class="num">{{ statistics.fang_number }}</div>
                  <div class="t">新增房源</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perLook">
                  <div class="num">{{ statistics.look_number }}</div>
                  <div class="t">新增带看</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perIn?type=1">
                  <div class="num">{{ statistics.follow_number }}</div>
                  <div class="t">新增跟进</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perParty?type=2">
                  <div class="num">{{ statistics.yaoshi_number }}</div>
                  <div class="t">新增钥匙方</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perParty?type=1">
                  <div class="num">{{ statistics.xinxi_number }}</div>
                  <div class="t">新增信息方</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perParty?type=3">
                  <div class="num">{{ statistics.weituo_number }}</div>
                  <div class="t">新增委托方</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perParty?type=4">
                  <div class="num">{{ statistics.image_number }}</div>
                  <div class="t">新增图片方</div>
                </router-link>
              </li>
              <li>
                <router-link to="/perParty?type=5">
                  <div class="num">{{ statistics.weihu_number }}</div>
                  <div class="t">新增维护方</div>
                </router-link>
              </li>
            </ul>
            <!--per-two end-->
          </div>
        </div>
        <!--per-box end-->
        <!--个人业绩 per-box start-->
        <div class="per-box">
          <div class="per-con">
            <div class="performance_head">
              <span class="span">个人业绩</span>
            </div>
            <div class="performance_line" id="performance_line1"></div>
          </div>
        </div>
        <!--per-box end-->
        <!--团队业绩 per-box start-->
        <div class="per-box" v-if="userInfo.position != 4">
          <div class="per-con">
            <div class="performance_head">
              <span class="span">团队业绩</span>
            </div>
            <div class="performance_line" id="performance_line0"></div>
          </div>
        </div>
        <!--per-box end-->
        <!--成交量 per-box start-->
        <div class="per-box">
          <div class="per-con">
            <div class="performance_head">
              <span class="span">成交量</span>
            </div>
            <div class="performance_line" id="performance_line2"></div>
          </div>
        </div>
        <!--per-box end-->
        <!--新增量 per-box start-->
        <div class="per-box">
          <div class="per-con">
            <div class="performance_head">
              <span class="span">新增量</span>
            </div>
            <div class="performance_line" id="performance_line3"></div>
          </div>
        </div>
        <!--per-box end-->
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token"]),
  },
  data() {
    return {
      value1: "",
      time: '2022-02-15', //
      totay:'',
      list:[],//前三名
      statistics:{},//每日统计
    };
  },
  methods: {
    changeTotay (e) {
      console.log(e);
    },
    //获取业绩
    getqiYeRanking() {
      this.$axios.qiYeRanking({ time: this.time }).then((res) => {
        this.list = res.data
      });
    },
    getuserRanking() {
      this.$axios.userRanking().then((res) => {
        let data = [];
        res.data.forEach((element) => {
          data.push([element.time, element.money]);
        });
        //个人业绩
        let myChart = echarts.init(
          document.getElementById("performance_line1")
        );
        myChart.setOption({
          xAxis: {
            type: "category",
            // boundaryGap: true,
            axisLabel: {
              splitNumber: 12,
              rotate: 35,
            },
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },

          series: [
            {
              name: "个人业绩",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#3273F6",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3273F6",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data,
            },
          ],
        });
      });
    },
    getusersRanking() {
      this.$axios.usersRanking().then((res) => {
        let data = [];
        res.data.forEach((element) => {
          data.push([element.time, element.money]);
        });
        //团队业绩
        let myChart0 = echarts.init(
          document.getElementById("performance_line0")
        );
        myChart0.setOption({
          color: "#3273F6",
          xAxis: {
            type: "category",

            axisLabel: {
              splitNumber: 12,
              rotate: 35,
            },
            // boundaryGap: true,
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },

          series: [
            {
              name: "个人业绩",
              type: "bar",
              smooth: 0.6,
              symbol: "none",
              itemStyle: {
                color: "#3273F6",
                normal: {
                  color: "#3273F6",
                },
              },
              data: data,
            },
          ],
        });
      });
    },
    getturnover() {
      this.$axios.turnover().then((res) => {
        let data = [];
        let data1 = []
        res.data.my.forEach((item) => {
          data.push([item.time, item.money]);
        });
        res.data.he.forEach((item) => {
          data1.push([item.time, item.money]);
        });
        //成交量
        let myChart2 = echarts.init(
          document.getElementById("performance_line2")
        );
        myChart2.setOption({
          color: ["#3273F6", "#FFA740"],
          legend: {
            left: "0",
            top: "5%",
            icon: "rect",
            textStyle: {
              color: "#999",
              fontsize: "12px",
            },
            itemWidth: 10, // 设置宽度
            itemHeight: 10, // 设置高度
            itemGap: 20, // 设置间距
          },
          xAxis: {
            type: "category",
            // boundaryGap: true,
            axisLabel: {
              splitNumber: 12,
              rotate: 35,
            },
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },
          yAxis: {
            type: "value",
            // boundaryGap: [0, '30%'],
            // boundaryGap: true,
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },

          series: [
            {
              name: "本司",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#3273F6",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3273F6",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data,
            },
            {
              name: "他司",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#FFA740",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FFA740",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data1,
            },
          ],
        });
      });
    },
    getnewCountList() {
      this.$axios.newCountList().then((res) => {
        let data = [];
        let data1 = [];
        res.data.my.forEach((item) => {
          data.push([item.time, item.money]);
        });
        res.data.he.forEach((item) => {
          data1.push([item.time, item.money]);
        });
        //新增量
        let myChart3 = echarts.init(
          document.getElementById("performance_line3")
        );
        myChart3.setOption({
          color: ["#3273F6", "#FFA740"],
          legend: {
            left: "0",
            top: "5%",
            icon: "rect",
            textStyle: {
              color: "#999",
              fontsize: "12px",
            },
            itemWidth: 10, // 设置宽度
            itemHeight: 10, // 设置高度
            itemGap: 20, // 设置间距
          },
          xAxis: {
            type: "category",
            // boundaryGap: true,
            axisLabel: {
              splitNumber: 12,
              rotate: 35,
            },
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },
          yAxis: {
            type: "value",
            // boundaryGap: [0, '30%'],
            // boundaryGap: true,
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },

          series: [
            {
              name: "本司",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#3273F6",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3273F6",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data
            },
            {
              name: "他司",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#FFA740",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FFA740",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data1
            },
          ],
        });
      });
    },
    getTargetInfo () {
      this.$axios.indexTongJi({time:this.totay / 1000}).then(res=>{
        this.statistics = res.data
      })
    }
  },
  created() {
    this.totay = new Date() * 1
    this.getqiYeRanking();
    this.getuserRanking();
    if (this.userInfo.position != 4) {
      this.getusersRanking();
    }
    this.getturnover();
    this.getnewCountList();
    this.getTargetInfo()
  }
};
</script>

<style lang="less" scoped>
.per-container {
  margin: 10px auto;
  .per-inner {
    margin: 0 -10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .per-box {
    width: 50%;
  }
  .per-con {
    margin: 10px;
    background: #fff;
    border-radius: 4px;
    padding: 15px 20px;
  }
  .performance_head .span::after {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    content: "";
    width: 4px;
    height: 20px;
    background: #3273f6;
    border-radius: 2px;
  }
  .performance_head .span {
    padding-left: 14px;
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    position: relative;
  }
  .per-date {
    cursor: pointer;
  }
  /deep/ .per-date .el-date-editor.el-input {
    width: 134px;
    cursor: pointer;
  }
  /deep/ .per-date .el-input__inner {
    border-radius: 16px;
    padding: 0;
    // padding-left: 10px;
    // background: url("../../assets/image/performance/per5.png") no-repeat 90% center;
    height: 32px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }
  /deep/ .per-date .el-input__prefix  {
    display: none;
  }
  /deep/ .el-input__suffix {
    
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .per-one {
    height: 218px;
    width: 510px;
    margin-left: auto;
    margin-right: auto;
    background: url("../../assets/image/performance/per1.png") no-repeat center;
    display: flex;
  }
  .per-one .li {
    width: 33.3333%;
    text-align: center;
  }
  .per-one .li .h-img {
    position: relative;
    margin-top: -10px;
  }
  .per-one .li .h-img .img {
    display: block;
    width: 78px;
    height: 78px;
    margin: 0 auto;
    border-radius: 100%;
  }
  .per-one .li .h-icon {
    width: 42px;
    height: 36px;
    margin: 0 auto;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 45px;
    position: relative;
    z-index: 2;
  }
  .per-one .li.li1 .h-icon {
    background: url("../../assets/image/performance/per2.png") no-repeat center;
  }
  .per-one .li.li1 {
    padding-top: 30px;
  }
  .per-one .li.li1 .h-img img {
    border: 2px solid #d7d7d7;
  }
  .per-one .li.li2 {
    padding-top: 15px;
  }
  .per-one .li.li2 .h-img img {
    border: 2px solid #ffc612;
  }
  .per-one .li.li2 .h-icon {
    background: url("../../assets/image/performance/per3.png") no-repeat center;
  }
  .per-one .li.li3 {
    padding-top: 30px;
  }
  .per-one .li.li3 .h-img img {
    border: 2px solid #cfa784;
  }
  .per-one .li.li3 .h-icon {
    background: url("../../assets/image/performance/per4.png") no-repeat center;
  }
  .per-one .li .h-name {
    font-size: 18px;
    margin: 15px 0px 10px 0px;
  }
  .per-one .per-rate {
    display: flex;
    justify-content: center;
  }
  .per-one .per-rate .star-icon {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }
  .per-two {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    height: 220px;
  }
  .per-two li {
    width: 25%;
    cursor: pointer;
    margin: 0px 0px 25px 0px;
  }
  .per-two li .num {
    color: #fc8214;
    font-size: 20px;
    font-weight: 600;
  }
  .per-two li .t {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
  }
  #performance_line0 {
    height: 300px;
  }
  #performance_line1 {
    height: 300px;
  }
  #performance_line2 {
    height: 300px;
  }
  #performance_line3 {
    height: 300px;
  }
}
</style>
