// 考试规则
<template>
  <div class="examRule p-t-30">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="800px"
    >
    <div class="examRule_head flex ">
      <span>考试规则</span>
      <i class="el-icon-circle-close" @click="close"></i>
    </div>
    <div class="examRule_main p-t-30 p-r-20 p-b-30 p-l-20" v-html="content"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      content:'',//规则
    }
  },
  methods:{
    //关闭
    close () {
      this.dialogVisible = false
    },
    //打开
    open () {
      this.$axios.kaoShi().then(res=>{
        this.dialogVisible = true
        this.content = res.data.content
      })
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header ,/deep/ .el-dialog__body {
  padding: 0 !important;
}

// .examRule_main {
//   // line-height: 28px;
// }
</style>
