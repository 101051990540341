// 业务规范
<template>
  <div class="business">
    <div class="business_box w-1200 margin-auto">
      <div class="business_tag flexs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ type == 1 ? '业务规范' : '大事件' }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="business_main p-l-40 p-r-40 b-c-f b-r-4">
        <ul class="business_ul">
          <router-link custom v-slot="{ navigate }" :to="'/details?type=' + type + '&id=' + item.id" v-for="(item,index) in list" :key="index">
            <li @click="navigate" @keypress.enter="navigate" role="link">
              <div class="business_li_main">
                <div class="business_li_name m-b-10">{{ item.title }}</div>
                <div class="business_li_time f-z-14 color-6">{{ item.createtime }}</div>
              </div>
              <div class="business_li_go f-z-14">查看详情>></div>
            </li>
            
          </router-link>
        </ul>
        <div class="paging" v-if="list.length != 0">
          <el-pagination
            background
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "city","region"]),
  },
  data () {
    return {
      page:1,//
      type:1,//1业务规范2大事件
      total:0,//
      list:[],
    }
  },
  methods:{
    changePage (e) {
      this.page = e
      this.getList()
    },
    getList () {
      this.$axios.articleList({
        page:this.page,
        type:this.type == 2 ? 1 : 2,
        city:this.region
      }).then(res=>{
        this.list = res.data.data
        this.total = res.data.total
      })
    }
  },
  created () {
    this.type = this.$route.query.type
    this.getList()
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-breadcrumb__inner {
  color: #3273F6 !important;
}
/deep/ .is-link {
  color: #666666 !important;
}
.business_ul {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #EBEBEB;
    &:last-child {
      border-bottom: none;
    }
    .business_li_name {
      font-size: 16px;
    }
    .business_li_go {
      color: #FF9600;
      cursor: pointer;
    }
  }
}
</style>