<template>
  <div class="microlecture">
    <!-- 头部轮播图 S -->
    <div class="microlecture_swiper">
      <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item,index) in bannerList" :key="index">
          <img :src="item.image" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <!-- 头部轮播图 E -->
    <!-- 考试 S -->
    <div class="microlecture_exam_box margin-auto w-1200" v-if="examMessage && userInfo && userInfo.position == 4 &&  userInfo.level== examMessage.level">
      <div class="microlecture_exam  m-t-30">
        <div class="microlecture_exam_name">{{ examMessage.title }}</div>
        <div class="microlecture_exam_time">考试时间{{ examMessage.start_time }}</div>
        <div class="microlecture_exam_footer flexs">
          <router-link custom v-slot="{ navigate }" :to="'/exam?id=' + examMessage.id + '&type=1'">
            <div class="microlecture_exam_btn center" @click="navigate" @keypress.enter="navigate" role="link">
            <span>开始考试</span>
            <img src="../../assets/image/microlecture/shape.png" alt="">
          </div>
          </router-link>
          
          <div class="microlecture_exam_role flexs" @click="$refs.exam.open()">
            <img src="../../assets/image/microlecture/rule.png" alt="">
            <span>考试规则</span>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 考试 E -->
    <div class="microlecture_box margin-auto w-1200">
      <div class="microlecture_nav center p-t-40 p-b-40">
        <div class="microlecture_nav_li center" :class="{active:from.type == index + 1}" v-for="(item,index) in 2" :key="index" @click="changeNav(index)">{{ index == 0 ? '视频课程' : '图文课程' }}</div>
      </div>
      <!-- 数据 S-->
      <div class="microlecture_main">
        <div class="microlecture_grade" v-if="courseList.length != 0">
          <div class="microlecture_grade_head"></div>
          <ul class="microlecture_grade_ul">
            <li @click="changeGrade(0)">
              <img src="../../assets/image/microlecture/record.png" alt="">
              
              <span>往期考核记录</span>
            </li>
            <li @click="changeGrade(1)" v-if="userInfo.position != 4">
              <img src="../../assets/image/microlecture/grade.png" alt="">
              <span>查看下属成绩</span>
            </li>
          </ul>
        </div>
        <ul class="microlecture_main_list flexs flex-wrap">
          <router-link custom v-slot="{ navigate }" :to="'/courseDetails?id=' + item.id + '&type=' + from.type" v-for="(item,index) in courseList" :key="index">
          <li @click="navigate" @keypress.enter="navigate" role="link" >
            <div class="microlecture_li_img">
              <!-- <img src="../../assets/image/publice/video.png" alt=""> -->
              <img :src="item.image" alt="">
              <div class="star_ul flexs">
                <div class="star_li" v-for="(item,index) in 5" :key="index">
                  <img src="" alt="">
                </div>
              </div>
              <div class="marking center">
                <img src="../../assets/image/publice/play.png" alt="">
              </div>
            </div>
            <div class="microlecture_li_main">
              <div class="microlecture_li_name m-b-10">{{ item.title }}</div>
              <div class="microlecture_li_title color-9">{{ item.text }}</div>
            </div>
          </li>
            
          </router-link>
          
        </ul>
      </div>
      <!-- 数据 E-->
      <!-- 分页 S-->
      <div class="paging" v-if="courseList.length != 0">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total">
        </el-pagination>
      </div>
      <!-- 分页 E-->
      <!-- 考试规则弹框 S -->
      <exam-rule ref="exam"></exam-rule>
      <!-- 考试规则弹框 E -->
    </div>
  </div>
</template>

<script>
import ExamRule from '../../components/ExamRule.vue'
import { mapGetters } from "vuex";
export default {
  components: { ExamRule },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["city"]),
  },
  data () {
    return {
      from:{
        peixun_type:1,//1=买卖培训2=租赁培训
        type:1,//1=视频课程2=图文课程
        city:'',//选择城市
        page:'1',//分页
      },
      bannerList:[],//轮播图
      courseList:[],//课程列表
      total:0,//总条数
      examMessage:{},//考试详情
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: true,
        loop: true
      },
      userInfo:{},//个人信息
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        this.from.peixun_type = newVal.query.type
        this.from.city = this.city
        this.getClassroom()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    changePage (e) {
      this.from.page = e
      this.getClassroom()
    },
    //侧边栏点击
    changeGrade (index) {
      if (index == 0) {
        this.$router.push({path:'/examRecord'})
      } else {
        this.$router.push({path:'/subordinateGrade'})
      }
    },
    //切换
    changeNav (index) {
      this.navIndex = index
      this.from.type = index + 1
      this.getClassroom()
    },
    //获取微课堂
    getClassroom () {
      this.$axios.classroom(this.from).then(res=>{
        this.courseList = res.data.data
        this.total = res.data.total
        if (!res.data.data.length) return this.$message({
          message: '暂无数据',
          type: 'warning'
        });
      })
    },
    //获取轮播图
    getBannerList () {
      this.$axios.classroomBanner().then(res=>{
        this.bannerList = res.data.data
      })
      this.$axios.kaoShi().then(res=>{
        this.examMessage = res.data
      })
    },
    getuserinfo () {
      this.$axios.userInfo().then(res => {
        this.userInfo = res.data
      })
    }
  },
  created () {
    this.from.city = this.city
    this.getuserinfo()
    this.getBannerList()
  }
}
</script>

<style lang="less" scoped>
.microlecture {
  background: #FFFFFF;
}
/deep/ .swiper {
  height: 360px !important;
}
.microlecture_nav {
  .microlecture_nav_li {
    width: 180px;
    height: 48px;
    cursor: pointer;
    color: #999999;
    font-size: 16px;
    font-weight: bold;
    background: #EBEBEB;
    border-radius: 4px;
    &:last-child {
      margin-left: 120px;
    }
  }
  .active {
    color: #FFFFFF;
    background: #FFA423;
  }
}
.microlecture_exam {
  height: 160px;
  padding: 30px 0 0 236px;
  background: url('../../assets/image/microlecture/exam.png') no-repeat;
  .microlecture_exam_name {
    color: #F84B32;
    font-size: 28px;
    font-weight: bold;
  }
  .microlecture_exam_time {
    margin: 6px 0 12px 0;
    color: #FFFFFF;
    font-size: 18px;
  }
  .microlecture_exam_btn {
    width: 200px;
    height: 40px;
    margin-right: 64px;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
      margin-left: 20px;
    }
    span {
      color: #F84B32;
      font-size: 24px;
      font-weight: bold;
    }
    background: url('../../assets/image/microlecture/btn.png') no-repeat;

  }
  .microlecture_exam_role {
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      color: #333333;
      font-size: 18px;
    }
  }
}
.microlecture_main {
  position: relative;
  .microlecture_grade {
    
    position: absolute;
    right: -20px;
    top: 0;
    .microlecture_grade_head {
      width: 86px;
      height: 48px;
      margin: 0 auto;
      background: url('../../assets/image/microlecture/gua.png') no-repeat;
    }
    .microlecture_grade_ul {
      width: 158px;
      padding: 0 10px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(50, 115, 246, 0.1);
      img {
        width: 32px;
        height: 32px;
      }
      span {
        color: #333333;
        font-size: 16px;
        margin-left: 10px;
      }
      li {
        cursor: pointer;
        padding: 15px 0;
        display: flex;
        align-items: center;
        &:first-child {
          border-bottom: 1px solid #EBEBEB;
        }
      }
    }
  }
  .microlecture_main_list {
    li {
      cursor: pointer;
      margin-right: 25px;
      margin-bottom: 25px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .microlecture_li_img {
        width: 280px;
        height: 210px;
        position: relative;
        .marking {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          background: rgba(51, 51, 51, 0.2);
          img {
            width: 48px;
            height: 48px;
          }
        }
        .star_ul {
          top: 10px;
          left: 10px;
          height: 28px;
          border-radius: 4px;
          padding: 0 6px;
          position: absolute;
          background: rgba(51, 51, 51, 0.5);
          .star_li {
            margin-right: 6px;
            &:last-child {
              margin-right: 0;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .microlecture_li_main {
        padding: 20px 16px;
        border: 1px solid #CCCCCC;
        border-top: none;
      }
      .microlecture_li_name {
        font-size: 18px;
        width: 240px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .microlecture_li_title {
        width: 240px;
        font-size: 12px;
        line-height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; // 显示的行数
        overflow: hidden;
      }
    }
  }
}
.paging {
  padding-top: 0;
}
</style>