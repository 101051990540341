<template>
  <div class="details">
    <el-dialog title="" :visible.sync="dialogVisible" width="600px" :show-close="false" :close-on-click-modal="false">
      <div class="details_head flex">
        <span>{{
          details.ways == 1 ? "求购" : details.ways == 2 ? "求租" : ""
        }}详情</span>
        <i class="el-icon-circle-close" @click="dialogVisible = false"></i>
      </div>
      <div class="details_main p-l-20 p-r-20 p-b-40">
        <div class="details_main_head flexs p-t-30 p-b-30 b-b-1">
          <div class="details_main_img m-r-20">
            <img :src="details.avatar" alt="" />
          </div>
          <div class="details_main_con">
            <div class="details_main_title m-b-20">{{ details.name }}</div>
            <div class="details_main_time color-9">
              发布时间：{{ details.createtime }}
            </div>
          </div>
        </div>
        <ul class="details_main_ul p-t-30 p-b-30">
          <li v-if="details.ways == 1">
            <div class="details_main_name m-r-20">首付预算：</div>
            <div class="details_main_txt">{{ details.first_price }}</div>
          </li>
          <li v-if="details.ways == 1">
            <div class="details_main_name m-r-20">总价预算：</div>
            <div class="details_main_txt">{{ details.total_price }}</div>
          </li>
          <li v-if="details.ways == 2">
            <div class="details_main_name m-r-20">租房预算：</div>
            <div class="details_main_txt">{{ details.total_price }}元/月</div>
          </li>
          <li v-if="details.ways == 1">
            <div class="details_main_name m-r-20">房型偏好：</div>
            <div class="details_main_txt">{{ details.house_preference }}</div>
          </li>
          <li v-if="(details.ways == 3 || details.ways == 4)">
            <div class="details_main_name m-r-20">小区名称：</div>
            <div class="details_main_txt">{{ details.xiaoqu_name }}</div>
          </li>
          <li v-if="(details.ways == 3 || details.ways == 4)">
            <div class="details_main_name m-r-20">小区地址：</div>
            <div class="details_main_txt">{{ details.city }}</div>
          </li>
          <li v-if="details.ways == 2 || details.ways == 3">
            <div class="details_main_name m-r-20">{{ details.ways == 2 ? '租房类型' : '出租方式' }}：</div>
            <div class="details_main_txt">{{ details.rent_type }}</div>
          </li>
          <li v-if="details.ways == 2">
            <div class="details_main_name m-r-20">居室：</div>
            <div class="details_main_txt">{{ details.type_preference }}</div>
          </li>
          <li v-if="details.ways == 2">
            <div class="details_main_name m-r-20">其它要求：</div>
            <div class="details_main_txt">{{ details.remark.join('、') }}</div>
          </li>
          <li v-if="details.ways != 1 && details.ways != 2">
            <div class="details_main_name m-r-20">期望{{ details.ways == 4 ? '售价' : '租金' }}：</div>
            <div class="details_main_txt">{{ details.total_price }} {{ details.ways == 3 ? '元/月' : '万' }}</div>
          </li>
          <li v-if="details.ways == 1 || details.ways == 3">
            <div class="details_main_name m-r-20">
              {{ details.ways == 1 ? "房屋需求：" : "房屋信息：" }}
            </div>
            <div class="details_main_txt">{{ details.loudong_number }}</div>
            <!-- <div class="details_main_list flexs">
              <div class="details_main_list_li center" v-for="(item, index) in details.remark" :key="index">
                {{ item }}
              </div>
            </div> -->
          </li>
        </ul>
        <div class="details_footer">
          <template v-if="isPhone">
            <el-button class="btn border" round>{{
              details.mobile
            }}</el-button>
            <el-button @click="entering" class="btn border" round>录入{{ details.ways == 1 || details.ways == 2 ? '客源' :
              '房源' }}</el-button>
          </template>

          <el-button v-if="!isPhone" class="btn" type="primary" round @click="isPhone = true">查看联系方式</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :show-close="false" :close-on-click-modal="false" :visible.sync="dialog" width="600px">
      <div class="alteration">
        <div class="alteration_txt">请选择变更客源类型</div>
        <div class="alteration_footer flex">
          <el-button @click="changeType(1)">私客源</el-button>
          <el-button @click="changeType(2)">公客源</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// "ways": 方式：1=求购；2=求租；3=出租；4=出售,
export default {
  data() {
    return {
      id: null,
      type: 1,//
      details: {},//
      isPhone: false, //
      dialogVisible: false,
      dialog: false
    };
  },
  methods: {
    changeType(type) {
      if (this.details.ways == 1 || this.details.ways == 2) {
        this.$axios.addClient({ ref_id: this.id, city: this.details.city, type: this.details.ways == 1 || this.details.ways == 4 ? 2 : 1, client_type: type, status: 1, booth_type: 2, house_type: this.details.type, mobile: this.details.mobile, name: this.details.name, address: this.details.address }).then(res => {
          this.$message({ message: res.msg, type: "success" });
          this.dialog = false
          this.$emit('success')
        })
      } else {
        this.$axios.houseZuAdd({
          ref_id: this.id,
          fang_type: this.details.nav_type,
          fang_level: 'A',
          fang_status: 1,
          yezhu_name: this.details.name,
          yezhu_mobile: this.details.mobile,
          fang_content: '',
          fang_city: this.details.city,
          fang_jingwei: "113.63141921,34.75343885",
          xiaoqu_id: this.details.xiaoqu_id,
          xiaoqu_loudong: this.details.loudong_number.split('-').join(','),
        }).then(res => {
          this.$message({ message: res.msg, type: "success" });
          this.dialogVisible = false;
          this.$router.push({
            path: "editRentHousing",
            query: { id: res.data, type: 2 },
          });
        }).catch(err => {
          this.dialogVisible = false;
          this.$router.push({
            path: "/housingDetails",
            query: { id: err.data.fang_id, type: 2 },
          });
        })
      }

    },
    entering() {
      if (this.details.ways == 1 || this.details.ways == 2) {
        this.dialogVisible = false
        this.dialog = true
      } else {
        this.changeType(1)
      }


    },
    open(id) {
      this.id = id
      this.$axios.poolDetail({ id }).then(res => {
        // if (res.data.loudong_number && res.data.loudong_number.split(',').length) {
        //   res.data.lou = res.data.loudong_number.split(',')[0]
        //   res.data.dan = res.data.loudong_number.split(',')[1];
        //   res.data.orientation = res.data.loudong_number.split(',')[3];
        // } else {
        //   res.data.lou = ''
        //   res.data.dan = '';
        //   res.data.orientation = '';
        // }
        if (res.data.loudong_number) res.data.loudong_number = res.data.loudong_number.split(',').join('-')
        this.isPhone = false;
        this.details = res.data;
        this.dialogVisible = true;
      })
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
}

.details_head {
  height: 56px;
  padding: 0 20px;
  background: #ededed;
  border-radius: 8px 8px 0px 0px;

  span {
    color: #333333;
    font-size: 18px;
    padding-left: 14px;
    position: relative;

    &::before {
      width: 4px;
      height: 24px;
      content: "";
      left: 0;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      background: #3273f6;
    }
  }

  /deep/ .el-icon-circle-close {
    font-size: 24px;
    cursor: pointer;
  }
}

.details_main {
  background: #ffffff;
  border-radius: 0 0 8px 8px;

  .details_main_img {
    width: 68px;
    height: 68px;

    img {
      border-radius: 50%;
    }
  }

  .details_main_title {
    font-size: 18px;
  }

  .details_main_time {
    font-size: 16px;
  }

  .details_main_name {
    color: #999999;
    font-size: 16px;
  }

  .details_main_txt {
    color: #333333;
    font-size: 16px;
  }

  .details_main_list_li {
    padding: 0 10px;
    height: 28px;
    color: #ff752a;
    font-size: 14px;
    background: rgba(255, 117, 42, 0.1);
    border-radius: 3px;
    margin-right: 10px;
  }
}

.details_main_ul {
  li {
    display: flex;
    margin-bottom: 18px;
    // align-items: center;
  }
}

.details_footer {
  display: flex;
  align-items: center;
  flex-direction: column;

  .border {
    color: #3273f6;
    margin-left: 0;
    border: 1px solid #3273f6;

    &:last-child {
      margin-top: 20px;
    }
  }

  .blue {
    margin-top: 20px;
    background: #3273f6;
  }
}

.btn {
  width: 480px;
}

.alteration {
  padding: 62px 80px 52px 80px;

  .alteration_txt {
    color: #333333;
    font-size: 22px;
    text-align: center;
    margin-bottom: 59px;
  }

  .alteration_footer {
    .el-button {
      width: 200px;
      height: 56px;
      color: #FFFFFF;
      font-size: 18px;
      background: #CCCCCC;
      border-radius: 28px;

      &:last-child {
        background: #3273F6;
      }
    }
  }
}
</style>
