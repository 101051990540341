<template>
  <div class="housing">
    <div class="housing_box margin-auto w-1400 m-t-20 b-c-f">
      <div class="housing_head p-t-40 p-b-40 b-b-1 m-b-40">
        <div class="housing_head_t m-b-30">
          <div class="housing_head_txt">类型</div>
          <ul class="housing_type flexs flex-wrap">
            <li
              @click="changeType('nav_type', item.value)"
              :class="{ active: ruleForm.nav_type == item.value }"
              v-for="(item, index) in getZuHousingTypeList()"
              :key="index"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <div class="housing_head_t">
          <div class="housing_head_txt">方式</div>
          <ul class="housing_type flexs flex-wrap">
            <li
              @click="changeType('ways', item.ways)"
              :class="{ active: ruleForm.ways == item.ways }"
              v-for="(item, index) in wayList"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <ul class="housing_ul flexs">
        <li v-for="(item, index) in houseList" :key="index" @click="changeItem(item.id)">
          <div class="housing_ul_img m-r-20">
            <img :src="item.avatar" alt="" />
          </div>
          <div class="housing_ul_con f-1">
            <template v-if="item.ways == 1">
              <div class="housing_ul_name">{{ item.name }}</div>
              <div class="housing_ul_list flexs m-t-10 m-b-10">
                <div
                  class="housing_ul_li center"
                  v-for="(item, index) in item.remark"
                  :key="index"
                >{{ item }}</div>
              </div>
              <div class="housing_ul_month flexs">
                <span>首付{{ item.first_price }}万</span>
                <span>总价{{ item.total_price }}万</span>
              </div>
            </template>
            <template v-else-if="item.ways == 2">
              <div class="housing_ul_main flex m-b-10">
                <div class="housing_ul_name">{{ item.name }}</div>
                <div class="housing_ul_price">{{ item.total_price }}/月</div>
              </div>
              <div class="housing_ul_list flexs">
                <div
                  class="housing_ul_li center"
                  v-for="(item, index) in item.remark"
                  :key="index"
                >{{ item }}</div>
              </div>
            </template>
            <template v-else-if="item.ways == 3">
               <div class="housing_ul_main flex">
                <div class="housing_ul_name">{{ item.name }}</div>
                <div class="housing_ul_price">{{ item.total_price }}/月</div>
              </div>
              <div class="housing_ul_address m-t-10 m-b-10">{{ item.city }}{{ item.address }}</div>
              <div class="housing_ul_list flexs">
                <div
                  class="housing_ul_li center"
                  v-for="(item, index) in item.remark"
                  :key="index"
                >{{ item }}</div>
              </div>
            </template>
            <template v-else>
              <div class="housing_ul_name">{{ item.name }}</div>
              <div class="housing_ul_address m-t-10 m-b-10">{{ item.city }}{{ item.address }}</div>
              <div class="housing_ul_price">{{ item.total_price }}万</div>
            </template>
          </div>
        </li>
      </ul>
      <div class="paging" v-if="houseList.length != 0">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 详情弹框 -->
    <taobao-details ref="taobao" @success="changePage(1)"></taobao-details>
  </div>
</template>

<script>
import TaobaoDetails from '../../components/TaobaoDetails.vue';
import {mapGetters} from "vuex";
export default {
  components: { TaobaoDetails },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      total: 0, //总条数
      details:{},//详情
      houseList: [], //淘宝池列表
      typeList: ["住宅", "公寓", "写字楼", "商铺", "别墅"],
      wayList: [
        // {
        //   name:'求购',
        //   ways:1,//
        // },
        {
          name:'求租',
          ways:2,//
        },
        {
          name:'出租',
          ways:3,//
        },
        // {
        //   name:'出售',
        //   ways:4,//
        // }
      ],
      ruleForm:{
        page:1,
        nav_type:2,//1新房；2 住宅；3 公寓；4 写字楼；5 商铺；6 别墅
        ways:2,//1=求购；2=求租；3=出租；4=出售
        pageNum:9
      }
    };
  },
  methods: {
    changePage (e) {
      this.ruleForm.page = e
      this.getHouseList()
    },
    changeItem (id) {
      this.$refs.taobao.open(id)
    },
    //切换
    changeType(type,value) {
      this.ruleForm.page = 1;
      this.ruleForm[type] = value
      this.getHouseList();
    },
    //获取房源列表
    getHouseList() {
      this.$axios
        .taoBaoPool(this.ruleForm)
        .then((res) => {
          this.houseList = res.data.data;
          this.total = res.data.total
        });
    },
  },
  created() {
    if ((this.userInfo.position === 3 || this.userInfo.position === 4) && this.userInfo.fang_type_privilege === 1) this.ruleForm.nav_type = 4
    this.getHouseList();
  },
};
</script>

<style lang="less" scoped>
.housing_box {
  padding: 0 100px;
  .housing_head_t {
    display: flex;
    .housing_head_txt {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .housing_type {
      li {
        cursor: pointer;
        font-size: 16px;
        color: #333333;
        margin-left: 40px;
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.housing_ul {
  flex-wrap: wrap;
  li {
    cursor: pointer;
    width: 380px;
    display: flex;
    padding: 30px 20px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
    border-radius: 4px;
    margin: 0 30px 30px 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .housing_ul_img {
      width: 68px;
      height: 68px;
      flex-shrink: 0;
      img {
        border-radius: 50%;
      }
    }
    .housing_ul_name {
      color: #333333;
      font-size: 18px;
    }
    .housing_ul_price {
      color: #FF2121;
      font-size: 20px;
    }
    .housing_ul_li {
      padding: 0 10px;
      height: 28px;
      color: #FF752A;
      font-size: 14px;
      margin-right: 10px;
      background: rgba(255, 117, 42, 0.1);
      border-radius: 3px;
    }
    .housing_ul_address {
      color: #999999;
      font-size: 14px;
      width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .housing_ul_month {
      span {
        flex: 1;
        &:first-child {
          color: #FF2121;
          font-size: 20px;
        }
        &:last-child {
          color: #999999;
          font-size: 18px;
        }
      }
    }
  }
}
.paging {
  padding-top: 20px;
}
</style>
