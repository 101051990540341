<template>
  <div class="talents">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="680"
    >
      <div class="examRule_head flex">
        <span>管理人才</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="talents_main">
        <ul class="talents_list">
          <li>
            <div class="talents_txt m-r-20">选择人才:</div>
            <div class="talents_list_select flex flex-wrap">
              <div class="talents_list_select_li m-b-20">
                <el-select
                  disabled
                  v-model="from.position1"
                  @change="changeArea"
                  placeholder="请选择区域总监"
                >
                  <el-option
                    v-for="item in areaList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="talents_list_select_li m-b-20">
                <el-select
                  @change="changeShop"
                  :disabled="isShop"
                  v-model="from.position2"
                  placeholder="请选择店长"
                >
                  <el-option
                    v-for="item in shopList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="talents_list_select_li">
                <el-select
                  @change="changeManager"
                  :disabled="isManager"
                  v-model="from.position3"
                  placeholder="请选择经理"
                >
                  <el-option
                    v-for="item in managerList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="talents_list_select_li">
                <el-select
                  @change="changeBroker"
                  :disabled="isBroker"
                  v-model="from.position4"
                  placeholder="请选择经纪人"
                >
                  <el-option
                    v-for="item in brokerList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </li>
          <li>
            <div class="talents_txt m-r-20">工号:</div>
            <div class="talents_main_ipt f-1">
              <el-input
                v-model="from.ren_id"
                disabled
                placeholder="请输入工号"
              ></el-input>
            </div>
          </li>
          <li>
            <div class="talents_txt m-r-20">电话:</div>
            <div class="talents_main_ipt f-1">
              <el-input
                disabled
                v-model="from.mobile"
                placeholder="请输入电话"
              ></el-input>
            </div>
          </li>
        </ul>
        <ul class="talents_ul">
          <li v-for="(item, index) in targetList" :key="index">
            <div class="talents_ul_time flexs">
              <div class="talents_ul_time_txt m-r-20">时间:</div>
              <div class="talents_ul_time_main flex f-1">
                <div class="talents_ul_time_select flexs">
                  <!-- <el-date-picker
                    v-model="item.startTime"
                    type="date"
                    placeholder="请选择开始日期"
                  >
                  </el-date-picker>
                  <span>-</span>
                  <el-date-picker
                    v-model="item.endTime"
                    type="date"
                    :disabled="!item.startTime"
                    placeholder="请选择结束日期"
                  >
                  </el-date-picker> -->
                  <el-date-picker
                    v-model="item.time"
                    type="daterange"
                    range-separator="-"
                    value-format="yyyy.MM.dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
                <div class="talents_ul_time_img" @click="addtarget(index)">

                  <img v-if="index == 0" src="../assets/image/talents/add.png" alt="">
                  <img v-else src="../assets/image/talents/mine.png" alt="">
                </div>
              </div>
            </div>
            <div class="talents_ul_ipt">
              <div class="talents_ul_time_txt m-r-20">培养目标:</div>
              <div class="talents_ul_ipt_main f-1">
                <el-input
                  type="textarea"
                  :rows="4"
                  resize="none"
                  placeholder="请输入培养目标"
                  v-model="item.target"
                >
                </el-input>
              </div>
            </div>
          </li>
        </ul>
        <div class="talents_footer center m-t-60">
          <el-button @click="submit">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      from: {
        city: "",
        position1: "",
        position2: "",
        position3: "",
        position4: "",
        level: "",
        ren_id: "",
        mobile: "", //电话
        text: "",
      },
      targetList: [],
      isShop: true, //店长
      isManager: true, //经理
      isBroker: true, //经纪人
      areaList: [], //区域
      managerList: [], //经理
      shopList: [], //店长
      brokerList: [], //经纪人
    };
  },
  methods: {
    addtarget(index) {
      if (index == 0) {
        this.targetList.push({
          time: "",
          target: "", //目标
        });
      } else {
        this.targetList.splice(index,1)
      }
    },
    open(id) {
      this.$axios.personnelDetail({ id }).then((res) => {
        let data = [];
        res.data.text.forEach((em) => {
          let ems = em.substring(1, em.length - 1);
          data.push({
            time: [ems.split("/")[0],ems.split("/")[1]],
            target: ems.split("/")[2], //目标
          });
        });
        this.targetList = data;
        this.from = res.data;
        this.dialogVisible = true;
      });
    },
    close() {
      this.dialogVisible = false;
    },
    //提交
    submit() {
      if (!this.from.position1)
        return this.$message({ message: "请选择区域总监", type: "warning" });
      if (!this.from.mobile.match(/^(0|86|17951)?1[3456789]\d{9}$/))
        return this.$message({ message: "请输入电话", type: "warning" });
      if (
        !this.targetList.every((em) => em.time) ||
        !this.targetList.every((em) => em.target)
      )
        return this.$message({
          message: "请将培养目标或时间填写完整",
          type: "warning",
        });
      // if (!this.from.position1) return this.$message({message: '请选择区域总监',type: 'warning'});
      let data = [];
      this.targetList.forEach((item) => {
        // item.timeTxt = '[' + item.time[0] + ',' + item.time[1] + ',' + item.target  +']'
        data.push(
          "[" + item.time[0] + "/" + item.time[1] + "/" + item.target + "]"
        );
      });
      this.from.text = data.join(",");
      this.from.city = this.$store.state.city;
      if (!this.from.text)
        return this.$message({
          message: "请输入培养目标或时间",
          type: "warning",
        });

      this.$axios.personnelEdit(this.from).then((res) => {
        if (res.code === 1) {
          this.dialogVisible = false;
          this.$emit("success");
          this.$message({ message: res.msg, type: "success" });
        }
      });
    },
    getList(type, id) {
      this.$axios
        .userRen({
          city: this.$store.state.city,
          position: type,
          id: id,
        })
        .then((res) => {
          if (res.code === 1) {
            switch (type) {
              case 1:
                this.areaList = res.data;
                break;
              case 2:
                this.shopList = res.data;
                break;
              case 3:
                this.managerList = res.data;
                break;
              case 4:
                this.brokerList = res.data;
                break;
              default:
                break;
            }
          }
        });
    },
    //选择区域
    changeArea(e) {
      this.isShop = false;
      let data = this.areaList.filter((em) => e == em.name)[0];
      this.from.level = data.level;
      this.from.ren_id = data.id;
      this.getList(2, data.id);
    },
    //选择店长
    changeShop(e) {
      this.isManager = false;
      let data = this.shopList.filter((em) => e == em.name)[0];
      this.from.level = data.level;
      this.from.ren_id = data.id;
      this.getList(3, data.id);
    },
    //选择经理
    changeManager(e) {
      this.isBroker = false;
      let data = this.managerList.filter((em) => e == em.name)[0];
      this.from.level = data.level;
      this.from.ren_id = data.id;
      this.getList(4, data.id);
    },
    //选择经纪人
    changeBroker(e) {
      let data = this.brokerList.filter((em) => e == em.name)[0];
      this.from.level = data.level;
      this.from.ren_id = data.id;
    },
  },
  created() {
    this.getList(1);
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.talents_main {
  padding: 30px 26px 60px 26px;
  border-radius: 0 0 8px 8px;
  background: #ffffff;
  .talents_list {
    li {
      display: flex;
      margin-bottom: 20px;
      .talents_txt {
        width: 80px;
        flex-shrink: 0;
        color: #666666;
        font-size: 16px;
        line-height: 40px;
        text-align: right;
      }
    }
  }
}
.talents_ul {
  li {
    padding: 20px;
    margin-bottom: 20px;
    background: #f5f5f5;
    &:last-child {
      margin-bottom: 0;
    }
    .talents_ul_time_txt {
      color: #666666;
      font-size: 16px;
      text-align: right;
      width: 80px;
      flex-shrink: 0;
      line-height: 40px;
    }
    .talents_ul_time_select {
      span {
        color: #cccccc;
        margin: 0 10px;
      }
      /deep/ .el-input {
        max-width: 180px;
      }
    }
    .talents_ul_time_img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .talents_ul_ipt {
      display: flex;
      margin-top: 20px;
    }
  }
}
.talents_footer {
  margin-top: 60px;
  .el-button {
    width: 480px;
    height: 56px;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
