<template>
  <div class="ment-page">
    <!--main-position start-->
    <div class="main-position w-1200">
       <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>业绩目标</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          from.type == 1 ? "今日目标" : "本月目标"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>新增约看</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--main-position end-->
    <!--ment-main start-->
    <div class="ment-main w-1200">
      <!--ment-side start-->
      <div class="ment-side">
        <!--side-list start-->
        <div class="side-list">
          <div class="title">大事件</div>
          <router-link tag="div"  :to="{ path: '/business', query: { type: 2 } }" class="list-item" v-for="(item,index) in incidentList" :key="index">
            <div class="t">{{ item.title }}</div>
            <div class="c">时间：{{ item.createtime }}</div>
          </router-link>
        </div>
        <!--side-list end-->
      </div>
      <!--ment-side end-->
      <!--ment-content start-->
      <div class="ment-content">
        <div class="personage_head">
          <div class="personage_head_top">
            <div class="personage_head_txt">类型:</div>
            <ul class="personage_head_ul">
              <li
                @click="changeType(item.value)"
                :class="{ active: item.value == from.house_type }"
                v-for="(item, index) in housingTypeList"
                :key="index"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
          <!-- <div class="personage_head_top">
            <div class="personage_head_txt">租售类型:</div>
            <ul class="personage_head_ul">
              <li v-for="(item, index) in 2" :key="index">{{ index == 0 ? '' : '' }}</li>
            </ul>
          </div> -->
        </div>
        <!--rate_page_list start-->
        <div class="rate_page_list">
          <ul class="personage_ul">
            <!--li start-->
            <li v-for="(item,index) in list" :key="index">
              <div class="personage_ul_img m-r-20">
                <img
                  src="https://pic1.ajkimg.com/display/ajk/f4ef3c1ab08cb7a9ed376343005a85ea/640x420c.jpg?t=1"
                  alt=""
                />
                <div class="personage_img_marking">A类</div>
              </div>
              <div class="personage_ul_main f-1">
                <div class="personage_ul_name">
                  富田城·九鼎华府富田城·九鼎华府
                </div>
                <div class="personage_ul_title">
                  合同期限：2020-10-10至2021-10-10
                </div>
                <div class="personage_ul_price">
                  <div class="personage_ul_price_left m-r-20">
                    <span>1800元/月</span>
                    <img src="../../assets/image/personage/up2.png" alt="" />
                  </div>
                  <div class="personage_ul_price_right">
                    <span>12652.2元/m²</span>
                    <img src="../../assets/image/personage/up1.png" alt="" />
                  </div>
                </div>
                <div class="appo-bot">
                  <div class="bot-txt">
                    <img
                      class="txt-img"
                      src="../../assets/image/personage/rule1.jpg"
                    />
                    <div class="txt">
                      <div class="t">林晓敏</div>
                      <div class="flex flex-between c">
                        <div class="color_orange m-r-30">
                          看房时间：2020-12-22 12:11
                        </div>
                        <div class="color_orange">
                          入住时间：2020-12-22 12:11
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tel">
                    <img
                      class="icon"
                      src="../../assets/image/personage/icon21.png"
                    />
                  </div>
                </div>
              </div>
            </li>
            <!--li end-->
          </ul>
        </div>
        <!--rate_page_list end-->
        <div class="paging" v-if="list.length">
          <el-pagination
            background
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!--ment-content end-->
    </div>
    <!--ment-main end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "city"]),
  },
  data() {
    return {
      total: 0, //条数
      list: [], //列表
      from:{
        types:1,
        type:2,
        house_type:1,//房源类型：1新房；2 住宅；3 公寓；4 写字楼；5 商铺；6 别墅
        page:1
      },
      incidentList:[]
    };
  },
  methods:{
    //切换类型
    changeType (type) {
      this.from.house_type = type
      this.init()
    },
    init () {
      this.from.page = 1
      this.list = []
      this.getList()
    },
    // 
     getList () {
      this.$axios.indexLook(this.from).then(res=>{
        this.list = res.data.data;
        this.total = res.data.total;
      })
    },
    //大事件列表
    getarticleList () {
      this.$axios.articleList({
        type:1,
        city:this.from.city
      }).then(res=>{
        this.incidentList = res.data.data
      })
    }
  },
  created () {
    this.from.city = this.city;
    this.from.types = this.$route.query.type;
    this.getList()
    this.getarticleList()
  }
};
</script>

<style scoped lang="less">
.appo-bot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bot-txt {
    display: flex;
    align-items: center;
    .txt-img {
      width: 48px;
      height: 48px;
      border-radius: 100%;
    }
    .txt {
      margin-left: 10px;
      .t {
        font-size: 16px;
      }
      .c {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
  .tel {
    .icon {
      width: 36px;
      height: 36px;
    }
  }
}
.personage_head {
  padding: 30px 30px;
  border-bottom: 1px solid #ebebeb;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.main-position {
  display: flex;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
  margin: 0 auto;
  .s {
    margin: 0 3px;
  }
}
.ment-main {
  margin: 0 auto 40px auto;
  display: flex;
  justify-content: space-between;
  .ment-side {
    width: 320px;
    .side-head {
      background: #fff;
      .title {
        background: url("../../assets/image/personage/a2.png") no-repeat center;
        background-size: cover;
        color: #fff;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        padding: 0 30px;
        position: relative;
      }
      .title:before {
        width: 4px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -10px;
        border-radius: 2px;
        background: #fff;
        display: block;
        content: "";
      }
      .side-nav {
        padding: 0 20px;
        .nav-li {
          padding: 20px 30px;
          border-bottom: 1px solid #ebebeb;
          font-size: 16px;
          display: flex;
          position: relative;
        }
        .nav-li:after {
          display: block;
          position: absolute;
          width: 20px;
          height: 100%;
          background: url("../../assets/image/personage/icon20.png") no-repeat
            center;
          right: 0px;
          top: 0px;
          content: "";
        }
        .nav-li.active {
          color: #3273f6;
        }
        .nav-li.active:after {
          background: url("../../assets/image/personage/icon22.png") no-repeat
            center;
        }
        .nav-li.on {
          background: url("../../assets/image/personage/icon15.png") no-repeat
            0px center;
        }
        .nav-li.off {
          background: url("../../assets/image/personage/icon24.png") no-repeat
            0px center;
        }
        .nav-li.on.active {
          background: url("../../assets/image/personage/icon23.png") no-repeat
            0px center;
        }
        .nav-li.off.active {
          background: url("../../assets/image/personage/icon16.png") no-repeat
            0px center;
        }
        .nav-li:nth-last-child(1) {
          border: none;
        }
      }
    }
    .side-list {
      background: #fff;
      padding: 10px 20px;
      .title {
        font-size: 18px;
        padding-left: 15px;
        position: relative;
        line-height: 2;
        background: url("../../assets/image/personage/icon20.png") no-repeat
          right center;
      }
      .title:before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -10px;
        background: #3273f6;
        display: block;
        width: 4px;
        height: 20px;
        border-radius: 2px;
        content: "";
      }
      .list-item {
        padding: 15px 0px;
        border-bottom: 1px solid #ebebeb;
        .t {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.6;
        }
        .c {
          font-size: 14px;
          color: #666;
          line-height: 1.6;
          margin-top: 5px;
        }
      }
      .list-item:nth-last-child(1) {
        border: none;
      }
    }
  }
  .ment-content {
    width: 860px;
    background: #fff;
  }
}
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-sze: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}
.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}
.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}
.rate_page_btn.red {
  background: #fddfdf;
  border: 1px solid #fddfdf;
  color: #ff5151;
}
.personage_ul {
  padding: 0 30px;
  li {
    padding: 30px 0px;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .tip {
      font-size: 14px;
    }
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
