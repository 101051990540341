<template>
  <div class="home">
    <div class="home_main margin-auto w-1200 fl flex-between m-t-30">
      <div class="home_left">
        <ul class="home_left_top flex">
          <li v-for="(item, index) in headList" :key="index" @click="changeHead(index)">
            <img :src="item.image" alt=""/>
            <span>{{ item.name }}</span>
          </li>
        </ul>
        <!-- 通告 S -->
        <div class="annunciate m-t-20 m-b-20 flex">
          <div class="annunciate_left center flex-direction">
            <img src="../../assets/image/home/annunciate.png" alt=""/>
            <span>通告</span>
          </div>
          <div class="annunciate_swiper">
            <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="(item, index) in messageList" :key="index">
                <ul class="swiper_list flex-direction">
                  <li class="flex" v-for="(em, ind) in item" :key="ind">
                    <span>{{ em.content }}</span>
                    <span>{{ em.createtime }}</span>
                  </li>
                </ul>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <!-- 通告 E -->
        <!-- 每日统计 S -->
        <div class="statistics">
          <div class="statistics_head flex">
            <div class="statistics_txt">
              <span>每日统计</span>
            </div>
            <div class="statistics_day center">
              <el-date-picker disabled :clearable="false" v-model="time" type="date" @change="changeTime"
                              :default-value="new Date()" placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <ul class="statistics_ul flex flex-wrap">
            <router-link to="/perBuyer" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.clienf_number }}</span>
                <span>新增客户</span>
              </li>

            </router-link>
            <router-link to="/perHouse" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.fang_number }}</span>
                <span>新增房源</span>
              </li>
            </router-link>
            <router-link to="/perLook" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.look_number }}</span>
                <span>新增带看</span>
              </li>
            </router-link>
            <router-link to="/perIn" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.follow_number }}</span>
                <span>新增跟进</span>
              </li>

            </router-link>
            <router-link to="/perParty?type=2" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.yaoshi_number }}</span>
                <span>新增钥匙方</span>
              </li>

            </router-link>
            <router-link to="/perParty?type=1" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.xinxi_number }}</span>
                <span>新增信息方</span>
              </li>

            </router-link>
            <router-link to="/perParty?type=3" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.weituo_number }}</span>
                <span>新增委托方</span>
              </li>

            </router-link>
            <router-link to="/perParty?type=4" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.image_number }}</span>
                <span>新增图片方</span>
              </li>

            </router-link>
            <router-link to="/perParty?type=5" v-slot="{ navigate, href }">
              <li :href="href" @click="navigate">
                <span>{{ statistics.weihu_number }}</span>
                <span>新增维护方</span>
              </li>

            </router-link>
          </ul>
        </div>
        <!-- 每日统计 E -->
        <!-- 展位房源 S -->
        <div class="housing m-t-20">
          <div class="housing_head flex">
            <div class="housing_head_txt">
              <span>展位房源</span>
            </div>
            <ul class="housing_head_ul flexs">
              <li :class="{ active: item.value == from.type }" v-for="(item, index) in getZuHousingTypeList()"
                  :key="index"
                  @click="changeBooth(item.value)">
                {{ item.label }}
              </li>
            </ul>
          </div>
          <ul class="housing_ul p-l-20 p-r-20" v-if="housingList.length">
            <router-link custom :key="index" v-for="(item, index) in housingList" v-slot="{ navigate,href }"
                         :to="{ name: 'HousingDetails', query: { id: item.id, type: 2 } }">
              <li @click="navigate" @keypress.enter="navigate" :href="href">
                <div class="housing_img m-r-20">
                  <img :src="item.fang_fengmian_image" alt=""/>
                </div>
                <div class="housing_ul_main">
                  <div class="housing_ul_top flex">
                    <div class="housing_ul_name">{{ item.name }}</div>
                    <div v-if="item.fang_pan_status" class="housing_ul_tab center"
                         :class="{ blue: item.fang_pan_status == '已开盘' }">
                      {{ item.fang_pan_status }}
                    </div>
                  </div>
                  <div class="housing_ul_sku">
                    {{ item.xiaoqu_loudong ? item.xiaoqu_loudong.replace(/,/g, "-") : '' }} {{ item.fang_xing }} | {{
                      item.fang_mianji
                    }}m² |
                    {{ item.look }}次 | {{ item.genjin_time }}天 |
                    {{ item.user_name }}
                  </div>
                  <div class="caution flex">
                    <div class="housing_ul_tag flexs flex-wrap">
                      <div class="housing_tag_li center m-r-10 m-t-5" v-for="(item, index) in item.fang_biaoqian"
                           :key="index">
                        {{ item }}
                      </div>
                    </div>
                    <div class="caution_img" v-if="item.genjin_status == 0">
                      <img src="../../assets/image/home/caution.png" alt=""/>
                    </div>
                  </div>
                  <div class="housing_ul_price">
                    <span>{{ item.can_price }}元/月</span>
                  </div>
                </div>
              </li>
            </router-link>
          </ul>
          <div class="paging" v-if="housingList.length">
            <el-pagination background @current-change="changePage" layout="prev, pager, next, jumper, ->, total"
                           :total="total">
            </el-pagination>
          </div>
        </div>
        <!-- 展位房源 E -->
      </div>
      <div class="home_right">
        <!-- 流程 S -->
        <div class="home_right_top">
          <ul class="home_right_top_ul center">
            <!-- <li @click="flowIndex = index" v-for="(item, index) in 2" :key="index" :class="{ active: index == flowIndex }">
              {{ index == 0 ? "买卖流程" : "租赁流程" }}
            </li> -->
            <li class="active">租赁流程</li>
          </ul>
          <div class="">
            <!-- <img :src="
                flowIndex == 0
                  ? flowMessage.buy_flow_image
                  : flowMessage.rent_flow_image
              " alt="" /> -->
            <img :src="flowMessage.rent_flow_image" alt="">
          </div>
        </div>
        <!-- 流程 E -->
        <!-- 业务范围 S -->
        <router-link custom v-slot="{ navigate,href }" :to="{ name: 'Business', query: { type: 1 } }">
          <div class="business b-c-f b-r-4 m-t-20 m-b-20 flex" :href="href" @click="navigate"
               @keypress.enter="navigate">
            <div class="business_left">
              <span>业务规范</span>
            </div>
            <div class="business_more">
              <img src="../../assets/image/publice/go.png" alt=""/>
            </div>
          </div>
        </router-link>
        <!-- 业务范围 E -->
        <!-- 大事件 S -->
        <div class="incident b-c-f b-r-4 p-l-20 p-r-20 p-t-20 p-b-20">
          <router-link :to="{ path: '/business', query: { type: 2 } }" custom v-slot="{ navigate,href }">
            <div class="incident_head flex" :href="href" @click="navigate" @keypress.enter="navigate">
              <div class="incident_txt label">
                <span>大事件</span>
              </div>
              <div class="incident_go">
                <img src="../../assets/image/publice/go.png" alt=""/>
              </div>
            </div>
          </router-link>
          <ul class="incident_ul">
            <router-link custom v-slot="{ navigate,href }" :to="{ path: '/details', query: { type: 2, id: item.id } }"
                         v-for="(item, index) in bigIncidentList" :key="index">
              <li class="b-b-1" @click="navigate" :href="href" @keypress.enter="navigate">
                <div class="incident_li_name flex">
                  <span>{{ item.title }}</span>
                  <span class="active" v-if="item.read_status == 1"></span>
                </div>
                <div class="incident_li_time f-z-14 color-6">
                  时间：{{ item.createtime }}
                </div>
              </li>
            </router-link>
          </ul>
        </div>
        <!-- 大事件 E -->

        <!-- 个人业绩 S -->
        <div class="performance m-t-20 b-c-f b-r-4 p-l-20 p-r-20 p-t-20 p-b-30">
          <div class="performance_head label">
            <span>个人业绩</span>
          </div>
          <div class="performance_line" id="performance_line" ref="performance_line"></div>
        </div>
        <!-- 个人业绩 E -->
        <!-- 成交量 S -->
        <div class="performance m-t-20 b-c-f b-r-4 p-l-20 p-r-20 p-t-20 p-b-30">
          <div class="performance_head label">
            <span>成交量</span>
          </div>
          <div class="performance_line" id="performance_line1" ref="performance_line1"></div>
        </div>
        <!-- 成交量 E -->

      </div>
    </div>
    <!-- 选择房源 S -->
    <select-housing @select="selectHousing" ref="selectHousing"></select-housing>
    <!-- 选择房源 E -->
    <!-- 申请小区 S -->
    <apply-plot ref="applyPlot"></apply-plot>
    <!-- 申请小区 E -->
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {mapGetters} from "vuex";
import SelectHousing from "@/components/SelectHousing.vue";
import ApplyPlot from "@/components/home/ApplyPlot.vue";
// import axios from '../../axios/index'

export default {
  components: {SelectHousing, ApplyPlot},
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token"]),
  },
  data() {
    return {
      fileList: [],
      from: {
        type: -1, //展位下标
        page: 1, //分页
      },
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        direction: 'vertical', // 垂直切换选项
        loop: true,
        autoplay: true
      },
      housingList: [], //房源列表
      headList: [
        {
          name: "添加房源",
          image: require("../../assets/image/home/house.png"),
          url: "",
        },
        {
          name: "添加客源",
          image: require("../../assets/image/home/ren.png"),
          url: "",
        },
        {
          name: "带看管理",
          image: require("../../assets/image/home/order.png"),
          url: "",
        },
      ],
      time: new Date(), //时间
      statistics: {}, //统计
      total: 0, //总条数
      bigIncidentList: [], //大事件
      flowIndex: 0, //
      flowMessage: {}, //流程图
      messageList: [],//消息
    };
  },
  methods: {
    //分页
    changePage(e) {
      this.from.page = e
      this.getHouseList()
    },
    //选择时间
    changeTime() {
      if (this.time) {
        this.getStatistics();
      }
    },
    //切换
    changeBooth(index) {
      this.from.page = 1;
      this.from.type = index;
      this.getHouseList();
    },
    //选择头部
    changeHead(index) {
      if (index == 0) {
        this.$refs.selectHousing.dialogVisible = true;
      } else if (index == 1) {
        this.$router.push({path: "/addPassenger"});
      } else {
        this.$router.push({path: "/Bespectacled"});
      }
    },
    //选择房源
    selectHousing(index) {
      if (index == 0) {
        this.$router.push({path: "/addHousing", query: {type: 1}});
      } else if (index == 1) {
        this.$router.push({path: "/addRentHousing", query: {type: 2}});
      } else if (index == 2) {
        console.log("000");

      } else if (index == 4) {
        this.$refs.applyPlot.open();
      } else if (index == 5) {
        this.$router.push({path: '/estate?type=1'})
      }
    },
    handleChange(file, fileList) {
      this.fileList3 = fileList.slice(-3);
    },
    //获取大事件列表
    getBigIncidentList() {
      this.$axios
          .articleList({
            type: 1,
            pageNum: 6,
            city: this.$store.state.region,
          })
          .then((res) => {
            this.bigIncidentList = res.data.data;
          });
      this.$axios.buyFlow().then((res) => {
        this.flowMessage = res.data;
      });
    },
    //获取展位房源
    getHouseList() {
      this.$axios
          .indexFang({
            ...this.from,
            city: this.$store.state.city,
          })
          .then((res) => {
            this.housingList = res.data.data;
            this.total = res.data.total;
          });
    },
    //获取每日统计
    getStatistics() {
      this.$axios
          .indexTongJi({
            time: this.time / 1000,
          })
          .then((res) => {
            this.statistics = res.data;
          });
    },
    getuserRanking() {
      this.$axios.userRanking().then((res) => {
        let data = [];
        res.data.forEach((element) => {
          data.push([element.time, element.money]);
        });
        //个人业绩
        let myChart = echarts.init(
            // document.getElementById("performance_line")
            this.$refs.performance_line
        );
        myChart.setOption({
          xAxis: {
            type: "category",
            // boundaryGap: true,
            axisLabel: {
              splitNumber: 12,
              rotate: 40,
            },
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },

          series: [
            {
              name: "个人业绩",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#3273F6",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3273F6",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data,
            },
          ],
        });
      });
    },
    getturnover() {
      this.$axios.turnover().then((res) => {
        let data = [];
        let data1 = []
        res.data.my.forEach((item) => {
          data.push([item.time, item.money]);
        });
        res.data.he.forEach((item) => {
          data1.push([item.time, item.money]);
        });
        //成交量
        let myChart2 = echarts.init(
            // document.getElementById("performance_line1")
            this.$refs.performance_line1
        );
        myChart2.setOption({
          color: ["#3273F6", "#FFA740"],
          legend: {
            left: "0",
            top: "5%",
            icon: "rect",
            textStyle: {
              color: "#999",
              fontsize: "12px",
            },
            itemWidth: 10, // 设置宽度
            itemHeight: 10, // 设置高度
            itemGap: 20, // 设置间距
          },
          xAxis: {
            type: "category",
            axisLabel: {
              splitNumber: 12,
              rotate: 40,
            },
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              // 设置网格
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EBEBEB",
              },
            },
          },

          series: [
            {
              name: "本司",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#3273F6",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#3273F6",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data,
            },
            {
              name: "他司",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#FFA740",
                width: 2,
              },
              areaStyle: {
                color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FFA740",
                  },
                  {
                    offset: 1,
                    color: "#F5F5F5",
                  },
                ]),
              },
              data: data1,
            },
          ],
        });
      });
    },
    //获取消息列表
    getmessageList() {
      this.$axios.messageList().then(res => {
        this.messageList = this.group(res.data.data, 2)

      })
    },
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    }
  },
  created() {
    this.getUserInfo();
    if ((this.userInfo.position === 3 || this.userInfo.position === 4) && this.userInfo.fang_type_privilege === 1) this.from.type = 4
    this.getBigIncidentList();
    this.getStatistics();
    this.getuserRanking()
    this.getturnover()
    this.getHouseList();
    this.getmessageList()

  }
};
</script>

<style lang="less" scoped>
.home_left {
  flex: 1;
  max-width: 760px;
  margin-right: 20px;
}

.home_left_top {
  li {
    width: 31.57894737%;
    // width: 240px;
    height: 180px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // margin-right: 20px;
    &:first-child {
      background: url("../../assets/image/home/add-f.png") no-repeat;
      background-size: cover;
    }

    &:nth-child(2) {
      background: url("../../assets/image/home/add-d.png") no-repeat;
      background-size: cover;
    }

    &:last-child {
      margin-right: 0;
      background: url("../../assets/image/home/add-k.png") no-repeat;
      background-size: cover;
    }

    img {
      width: 48px;
      height: 48px;
      margin-bottom: 20px;
    }

    span {
      font-size: 24px;
      color: #ffffff;
    }
  }
}

.home_right {
  width: 35%;
}

.annunciate {
  width: 760px;
  height: 112px;
  background: #ffffff;
  border-radius: 4px;

  .annunciate_left {
    width: 76px;
    position: relative;

    img {
      width: 28px;
      height: 28px;
    }

    span {
      color: #ff2121;
      font-size: 18px;
      margin-top: 6px;
    }

    &::after {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      position: absolute;
      content: "";
      width: 1px;
      height: 48px;
      background: #ebebeb;
    }
  }

  .annunciate_swiper {
    flex: 1;

    .swiper {
      width: calc(760px - 76px);
      height: 112px;

      .swiper_list {
        height: 100%;
        padding: 30px 20px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.statistics {
  padding: 20px 20px 30px 20px;
  background: #ffffff;
  border-radius: 4px;

  .statistics_txt {
    span {
      padding-left: 14px;
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      position: relative;

      &::after {
        left: 0;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        position: absolute;
        content: "";
        width: 4px;
        height: 20px;
        background: #3273f6;
        border-radius: 2px;
      }
    }
  }

  .statistics_day {
    width: 150px;
    height: 32px;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 16px;

    span {
      color: #666666;
      font-size: 16px;
    }

    img {
      width: 16px;
      height: 9px;
      margin-left: 10px;
    }
  }

  .statistics_ul {
    li {
      width: 25%;
      margin-top: 30px;
      display: flex;
      cursor: pointer;
      align-items: center;
      flex-direction: column;

      span {
        &:first-child {
          color: #fc8214;
          font-size: 20px;
        }

        &:last-child {
          color: #666666;
          font-size: 16px;
          margin-top: 9px;
        }
      }
    }
  }
}

.housing {
  background: #ffffff;
  border-radius: 4px;

  .housing_head {
    height: 60px;
    padding: 0 30px 0 20px;
  }

  .housing_head_txt {
    span {
      padding-left: 14px;
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      position: relative;

      &::after {
        left: 0;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        position: absolute;
        content: "";
        width: 4px;
        height: 20px;
        background: #3273f6;
        border-radius: 2px;
      }
    }
  }

  .housing_head_ul {
    li {
      cursor: pointer;
      margin-left: 40px;
      color: #666666;
      font-size: 16px;
    }

    .active {
      color: #3273f6;
      font-weight: bold;
    }
  }
}

.housing_ul {
  border-top: 1px solid #ebebeb;

  li {
    display: flex;
    padding: 30px 0;
    cursor: pointer;
    border-bottom: 1px solid #ebebeb;

    &:last-child {
      border-bottom: none;
    }

    .housing_img {
      width: 220px;
      height: 165px;

      img {
        border-radius: 8px;
      }
    }

    .housing_ul_main {
      height: 165px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }

    .housing_ul_name {
      color: #333333;
      font-size: 18px;
      font-weight: bold;
    }

    .housing_ul_tab {
      padding: 0 8px;
      height: 28px;
      font-size: 14px;
      border-radius: 3px;
      color: #ffa914;
      background: #fffaf0;
    }

    .blue {
      color: #3273f6;
      background: #e1ebff;
    }

    .gray {
      color: #999999;
      background: #f5f5f5;
    }

    .housing_ul_sku {
      color: #999999;
      font-size: 14px;
    }

    .housing_ul_price {
      span {
        color: #ff2121;
        font-size: 22px;
        font-weight: bold;
      }
    }

    .housing_tag_li {
      padding: 0 10px;
      height: 28px;
      color: #999999;
      font-size: 14px;
      background: #f5f5f5;
      border-radius: 3px;
    }

    .caution_img {
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }
  }
}

.home_right_top {
  padding: 0 20px;
  background: #ffffff;
  border-radius: 4px;

  .home_right_top_ul {
    li {
      cursor: pointer;
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      padding: 20px 0;
    }

    .active {
      color: #3273f6;
      position: relative;

      &::after {
        content: "";
        bottom: 0;
        position: absolute;
        width: 24px;
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
        background: #3273f6;
      }
    }
  }
}

.business {
  cursor: pointer;
  padding: 24px 20px;

  span {
    padding-left: 14px;
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    position: relative;

    &::after {
      left: 0;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      position: absolute;
      content: "";
      width: 4px;
      height: 20px;
      background: #3273f6;
      border-radius: 2px;
    }
  }

  .business_more {
    width: 9px;
    height: 16px;
  }
}

.performance_line {
  height: 300px;
}

.incident_go {
  width: 9px;
  height: 16px;
}

.incident_ul {
  li {
    cursor: pointer;
    padding: 20px 0;

    &:last-child {
      border-bottom: none;
    }

    .incident_li_name {
      margin-bottom: 14px;

      span {
        &:first-child {
          width: 360px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #333333;
          font-size: 16px;
        }
      }

      .active {
        width: 8px;
        height: 8px;
        background: #ff2121;
        border-radius: 50%;
      }
    }
  }
}
</style>
