<template>
  <div class="applyPlot">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="568px"
    >
      <div class="examRule_head flex">
        <span>申请小区</span>
        <i class="el-icon-circle-close" @click="handleClose"></i>
      </div>
      <div class="applyPlot_main">
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
          <el-form-item label="小区名称:" prop="name">
            <el-input v-model="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="区域:" prop="city">
            <el-cascader
              size="medium"
              :options="regionList"
              v-model="region"
              placeholder="请选择"
              @change="handleChangeCity"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址:" prop="address">
            <el-input @input="getLocation" v-model="form.address" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="小区首字母:" prop="letter">
            <el-input v-model="form.letter" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="小区类型:" prop="fang_type">
            <el-select v-model="form.fang_type" placeholder="请选择">
              <el-option
                v-for="item in getZuHousingTypeList().filter(option => option.value !== -1)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button type="primary" round @click="submitForm('form')">提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      dialogVisible: false,
      region: ['410000','410100','410101'], //区域
      form: {
        name: "",
        city: "河南省/郑州市/郑东新区",
        address: "",
        fang_type: "",
        log:'',
        lat:'',
        letter:''
      },
      options: [
        {
          value: 2,
          label: "住宅"
        },
        {
          value: 3,
          label: "公寓"
        },
        {
          value: 4,
          label: "写字楼"
        },
        {
          value: 5,
          label: "商铺"
        },
        {
          value: 6,
          label: "别墅"
        },
        {
          value: 7,
          label: "安置小区"
        }
      ],
      rules: {
        name: [
          { required: true, message: "请输入小区名称", trigger: "blur" }
        ],
        city: [
          { required: true, message: "请选择区域", trigger: "change" },
        ],
        letter: [
          { required: true, message: "请输入小区首字母", trigger: "change" },
        ],
        address: [{ required: true, message: "请填写详细地址", trigger: "blur" }],
        fang_type:[{ required: true, message: "请选择小区类型", trigger: "change" }]
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          
          this.$axios.xiaoQuApply(this.form).then(res=>{
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.dialogVisible = false
            for (const key in this.form) {
              this.form[key] = ''
            }
            this.region = ['410000','410100','410101']
            this.form.city = '河南省/郑州市/郑东新区'
          })
        } else {
          return false;
        }
      });
    },
    open() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.region = ['410000','410100','410101']
      this.form.city = '河南省/郑州市/郑东新区'
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.region.length; i++) {
        data.push(this.CodeToText[this.region[i]]);
      }
      this.form.city = data.join("/");
    },
    //获取经纬度
    getLocation () {
      if (this.form.address && this.form.city) {
        this.$axios.address({address:this.form.city.split('/').join('') + this.form.address}).then(res=>{
          let location = res.data.result.location
          this.form.log = location.lng
          this.form.lat = location.lat
        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: none !important;
}
/deep/ .el-cascader,
/deep/ .el-select,/deep/ .el-button {
  width: 100%;
}
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.housing_mian {
  .el-icon-circle-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
  }
}
.applyPlot_main {
  padding: 40px 40px;
  background: #ffffff;
}
</style>
