<template>
  <div class="forget">
    <div class="forget_head b-c-f m-b-40">
      <div class="forget_box w-1200 margin-auto flex">
        <img src="../../assets/image/publice/logo.png" alt="" />
        <span @click="login">登录账号</span>
      </div>
    </div>
    <div class="forget_con w-1200 margin-auto b-c-f">
      <div class="forget_txt center">忘记密码</div>
      <el-form
        ref="from"
        :rules="rules"
        class="form"
        :model="from"
        label-width="0px"
      >
        <ul class="forget_ul">
          <li>
            <div class="forget_ul_img center">
              <img src="../../assets/image/login/phone.png" alt="" />
            </div>
            <div class="forget_ul_ipt">
              <el-form-item prop="mobile">
                <el-input
                  v-model="from.mobile"
                  maxlength="11"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
            </div>
          </li>
          <li>
            <div class="forget_ul_img center">
              <img src="../../assets/image/login/code.png" alt="" />
            </div>
            <el-form-item prop="captcha">
              <div class="forget_ul_ipt flex">
                <el-input
                  v-model="from.captcha"
                  maxlength="6"
                  placeholder="请输入验证码"
                ></el-input>
                <el-button :loading="disabled" @click="getCode">{{
                  codeTxt
                }}</el-button>
              </div>
            </el-form-item>
          </li>
          <li>
            <div class="forget_ul_img center">
              <img src="../../assets/image/login/pwd.png" alt="" />
            </div>
            <div class="forget_ul_ipt">
              <el-form-item prop="password">
                <el-input
                  type="password"
                  v-model="from.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </div>
          </li>
          <li>
            <div class="forget_ul_img center">
              <img src="../../assets/image/login/pwd.png" alt="" />
            </div>
            <div class="forget_ul_ipt">
              <el-form-item prop="pwd">
                <el-input
                  type="password"
                  v-model="from.pwd"
                  placeholder="请再次输入密码"
                ></el-input>
              </el-form-item>
            </div>
          </li>
        </ul>
      </el-form>
      <div class="forget_btn">
        <el-button @click="submit('from')">提交</el-button>
      </div>
    </div>
    <div class="record center f-z-14 color-6">
      <a href="">豫公安网备案号1462114</a> Copyright ©2019 郑州科技有限有限公司
      版权所有
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validateMoblie = (rule, value, callback) => {
      if (this.from.mobile.match(/^(0|86|17951)?1[3456789]\d{9}$/)) {
        callback();
      } else {
        callback(new Error("请输入正确的手机号"));
      }
    };
    var validatePwd = (rule, value, callback) => {
      if (this.from.password == this.from.pwd) {
        callback();
      } else {
        callback(new Error("两次输入的密码不一致"));
      }
    };
    return {
      input: "",
      from: {
        mobile: "", //手机号// 18500000000
        password: "", //密码
        pwd: "", //
        captcha: "", //验证码
      },
      codeTxt: "获取验证码",
      sending: true,
      second: 60,
      disabled: false,
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMoblie, trigger: "blur" },
        ],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { min: 6, max: 18, message: '密码长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: "请输入确认登录密码", trigger: "blur" },
          { validator: validatePwd, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //提交
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .passwordForget(this.from)
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              setTimeout(()=>{

                this.$router.push({name:'Login'})
              },800)
            })
            .catch((res) => {
              console.log(res);
            });
        } else {
          return false;
        }
      });
    },
    //获取验证码
    getCode() {
      if (
        !this.from.mobile ||
        !this.from.mobile.match(/^(0|86|17951)?1[3456789]\d{9}$/)
      )
        return this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
      if (!this.sending) return;
      this.sending = false;
      this.$axios
        .captcha({
          mobile: this.from.mobile,
          type: 1,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.timeDown();
          } else {
            this.$message.error(res.msg);
          }
          this.disabled = true;
        });
    },
    timeDown() {
      let result = setInterval(() => {
        --this.second;
        this.codeTxt = this.second + "S";
        if (this.second < 0) {
          clearInterval(result);
          this.sending = true;
          this.disabled = false;
          this.second = 60;
          this.codeTxt = "获取验证码";
        }
      }, 1000);
    },
    //去登陆
    login() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style lang="less" scoped>
.forget_box {
  height: 160px;
  img {
    width: 214px;
    height: 74px;
  }
  span {
    cursor: pointer;
    color: #323232;
    font-size: 30px;
  }
}
.forget_txt {
  font-size: 28px;
  padding: 60px 0;
}
.forget_con {
  padding: 0 300px 120px 300px;
}

.forget_ul {
  li {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    height: 64px;
    padding-right: 15px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    .forget_ul_ipt {
      height: 100%;
      flex: 1;
      /deep/ .el-input__inner {
        border: none;
        height: 62px;
        width: 100%;
        font-size: 16px;
        // line-height: 64px;
      }
      /deep/ .el-button {
        width: 112px;
        height: 36px;
        line-height: 36px;
        color: #ffffff;
        font-size: 16px;
        padding: 0;
        background: #3273f6;
        border-radius: 4px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    .forget_ul_img {
      width: 68px;
      position: relative;
      img {
        width: 28px;
        height: 28px;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 32px;
        background: #cccccc;
      }
    }
  }
}
.forget_btn {
  margin-top: 60px;
  width: 100%;
  .el-button {
    width: 100%;
    height: 64px;
    color: #fffefe;
    font-size: 24px;
    background: linear-gradient(90deg, #3273f6, #528bff);
    border-radius: 8px;
  }
}
.record {
  padding: 64px 0;
}
/deep/ .el-form-item {
  margin-bottom: 0;
  flex: 1;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
