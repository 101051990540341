<template>
  <div class="ment-page">
    <!--main-position start-->
    <div class="main-position w-1200">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>业绩统计</el-breadcrumb-item>
        <el-breadcrumb-item class="blue_color">新增跟进</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--main-position end-->
    <!--ment-main start-->
    <div class="ment-main w-1200">
      <!--ment-side start-->
      <div class="ment-side">
        <div class="side-head">
          <div class="title">新增跟进</div>
          <!-- <span class="color_red">（100）</span> -->
          <el-menu
            unique-opened
            :default-active="activeName"
            class="el-menu-vertical-demo"
            @select="handleSelect">
            <el-menu-item index="1">
              <img class="menu-icons" src="../../assets/image/personage/icon17.png" alt="">
              <img class="menu-icon" src="../../assets/image/personage/icon26.png" alt="">
              <span slot="title">客源</span>
            </el-menu-item>
            <el-menu-item index="2">
              <img class="menu-icons" src="../../assets/image/personage/icon19.png" alt="">
                <img class="menu-icon" src="../../assets/image/personage/icon18.png" alt="">
              <span slot="title">房源</span>
            </el-menu-item>
            <!-- <el-submenu index="2">
              <template slot="title">
                <img class="menu-icons" src="../../assets/image/personage/icon19.png" alt="">
                <img class="menu-icon" src="../../assets/image/personage/icon18.png" alt="">
                <span>房源</span>
              </template>
              <el-menu-item index="1-1">手机号跟进</el-menu-item>
              <el-menu-item index="1-2">楼栋号跟进</el-menu-item>
            </el-submenu> -->
            
          </el-menu>
        </div>
        <!--side-list start-->
        <div class="side-list">
          <div class="title">大事件</div>
          <router-link tag="div" :to="{ path: '/details', query: { type: 2 ,id: item.id} }" class="list-item" v-for="(item,index) in incidentList" :key="index">
            <div class="t">{{ item.title }}</div>
            <div class="c">时间：{{ item.createtime }}</div>
          </router-link>
        </div>
        <!--side-list end-->
      </div>
      <!--ment-side end-->
      <!--ment-content start-->
      <div class="ment-content">
        <div class="per-search flex">
          <i class="el-icon-search icon"></i>
          <div class="search-input">
            <el-input v-model="from.search_name" placeholder="请输入搜索内容"></el-input>
          </div>
          <el-button @click="changeData">搜索</el-button>
        </div>

        <!--手机号跟进ment-list start-->
        <div class="ment-list" v-for="(item,index) in list" :key="index">
          <!--ment-list-item start-->
          <div class="ment-list-item flex-between flex" >
            <div class="flex-l mflex-l">
              <div class="item" v-if="activeName == 1">
                <div class="li">
                  <span class="color-9">跟进时间：</span> {{ item.createtime }}
                </div>
                <div class="li">
                  <span class="color-9">跟进客户：</span>{{ item.rec_name }}
                </div>
                <div class="li">
                  <span class="color-9">联系方式：</span>{{ item.rec_mobile }}
                </div>
              </div>
              <div class="item" v-else>
               <div class="li">
                  <span class="color-9">跟进时间：</span> {{ item.createtime }}
                </div>
                <div class="li">
                  <span class="color-9">房源信息：</span> {{ item.xiaoqu_name }} {{ item.xiaoqu_loudong }}
                </div>
                <div class="li">
                  <span class="color-9">经纪人：</span> {{ item.user_name }}  {{ item.user_id }}
                </div>
              </div>
              <!-- <div class="item" v-if="activeName == 1">
                <span class="color-9">房源信息：</span>中原区电厂路荆河路58号
              </div> -->
              <div class="item" v-if="activeName != 1">
                <span class="color-9">小区地址：</span>{{ item.address }}
              </div>
              <div class="item">
                <span class="color-9">跟进内容：</span
                >{{ item.remark }}
              </div>
            </div>
            <!-- <div class="mobile-icon m-l-15">
              <img class="icon" src="../../assets/image/personage/icon25.png" />
            </div> -->
          </div>
          <!--ment-list-item end-->
        </div>
        <!--手机号跟进ment-list end-->
        <div class="paging" v-if="list.length">
          <el-pagination
            background
            :current-page="from.page"
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!--ment-content end-->
    </div>
    <!--ment-main end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "token", "city","region"]),
  },
  data() {
    return {
      activeName: "1",
      total: 0, //条数
      list: [], //列表
      from:{
        uid:null,
        types:1,//1 今日；2 本月【业绩目标->本月目标用到】
        type:1,//1=客源2=房源
        house_type:'',//房源类型：1新房；2 住宅；3 公寓；4 写字楼；5 商铺；6 别墅
        page:1,
        search_name:''
      },
      incidentList:[],//
    };
  },
  methods:{
    changeData () {
      this.from.page = 1
      this.getList()
    },
    handleSelect (e) {
      this.activeName = e
      if (e == 1) {
        this.from.type = 1
      } else {
        this.from.type = 2
      }
      this.from.page = 1
      this.getList()
    },
    changePage (e) {
      this.from.page = e
      this.getList()
    },
    getList () {
      this.$axios.indexGenJin(this.from).then(res=>{
        this.list = res.data.data
        this.total = res.data.total
      })
    },
    //大事件列表
    getarticleList() {
      this.$axios
        .articleList({
          type: 1,
          city: this.from.city,
          pageNum:6
        })
        .then((res) => {
          this.incidentList = res.data.data;
        });
    },
  },
  created () {
    // this.from.city = this.region;
    this.from.types = this.$route.query.type;
    // this.from.uid = this.$route.query.id;
    this.getList()
    this.getarticleList();
  }
};
</script>

<style scoped lang="less">
/deep/ .el-menu {
  border-right: none;
}
.menu-icon,.menu-icons {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.menu-icons {
  display: none;
}
/deep/ .el-menu-item.is-active .menu-icons {
  display: inherit;
}
/deep/ .el-menu-item.is-active .menu-icon {
  display: none;
}
/deep/ .el-submenu.is-opened .menu-icons {
  display: inherit;
}
/deep/ .el-submenu.is-opened .menu-icon {
  display: none;
}
/deep/ .el-menu-item {
  display: flex;
  align-items: center;
}
/deep/ .el-submenu__title {
  display: flex;
  align-items: center;
}
.mflex-l {
  width: calc(100% - 40px);
}
.per-search {
  width: 560px;
  border: 2px solid #ebebeb;
  height: 36px;
  position: relative;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 30px;
  .icon {
    color: #ccc;
    margin: 0 10px;
  }
  .search-input {
    width: 400px;
  }
  /deep/ .search-input .el-input__inner {
    border: none;
    padding: 0;
    height: 32px;
    padding: 0;
    line-height: 32px;
    font-size: 16px;
    text-align: left;
  }
  /deep/ .el-button {
    background: #3273f6;
    width: 100px;
    height: 36px;
    padding: 0;
    border: none;
    border-radius: 0;
    color: #fff;
    position: absolute;
    right: 0px;
    top: -2px;
    font-size: 18px;
  }
}
.personage_head {
  padding: 30px 30px;
  border-bottom: 1px solid #ebebeb;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.main-position {
  display: flex;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
  margin: 0 auto;
  .s {
    margin: 0 3px;
  }
}
.ment-main {
  margin: 0 auto 40px auto;
  display: flex;
  justify-content: space-between;
  .ment-side {
    width: 320px;
    .side-head {
      background: #fff;
      .title {
        background: url("../../assets/image/personage/a2.png") no-repeat center;
        background-size: cover;
        color: #fff;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        padding: 0 30px;
        position: relative;
      }
      .title:before {
        width: 4px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -10px;
        border-radius: 2px;
        background: #fff;
        display: block;
        content: "";
      }
      .side-nav {
        padding: 0 20px;
        .nav-li {
          padding: 20px 30px;
          border-bottom: 1px solid #ebebeb;
          font-size: 16px;
          display: flex;
          position: relative;
        }
        .nav-li:after {
          display: block;
          position: absolute;
          width: 20px;
          height: 100%;
          background: url("../../assets/image/personage/icon20.png") no-repeat
            center;
          right: 0px;
          top: 0px;
          content: "";
        }
        .nav-li.active {
          color: #3273f6;
        }
        .nav-li.active:after {
          background: url("../../assets/image/personage/icon22.png") no-repeat
            center;
        }
        .nav-li.on {
          background: url("../../assets/image/personage/icon15.png") no-repeat
            0px center;
        }
        .nav-li.off {
          background: url("../../assets/image/personage/icon24.png") no-repeat
            0px center;
        }
        .nav-li.on.active {
          background: url("../../assets/image/personage/icon23.png") no-repeat
            0px center;
        }
        .nav-li.off.active {
          background: url("../../assets/image/personage/icon16.png") no-repeat
            0px center;
        }

        .nav-li.p {
          background: url("../../assets/image/personage/icon26.png") no-repeat
            0px center;
        }
        .nav-li.h {
          background: url("../../assets/image/personage/icon18.png") no-repeat
            0px center;
        }
        .nav-li.p.active {
          background: url("../../assets/image/personage/icon17.png") no-repeat
            0px center;
        }
        .nav-li.h.active {
          background: url("../../assets/image/personage/icon19.png") no-repeat
            0px center;
        }

        .nav-li:nth-last-child(1) {
          border: none;
        }
        /deep/ .el-collapse {
          border: none;
        }
        /deep/ .el-collapse-item__arrow {
          display: none;
        }
        /deep/ .el-collapse-item__header {
          display: inline;
          border: none;
          height: inherit;
          line-height: inherit;
        }
        /deep/ .el-collapse-item__wrap {
          border: none;
        }
        /deep/ .el-collapse .nav-li {
          border: none;
        }
        .links-a {
          display: block;
          font-size: 16px;
          line-height: 1.6;
          padding: 5px 30px;
        }
        .links-a.active {
          color: #3273f6;
        }
      }
    }
    .side-list {
      margin: 20px 0px;
      background: #fff;
      padding: 10px 20px;
      .title {
        font-size: 18px;
        padding-left: 15px;
        position: relative;
        line-height: 2;
        background: url("../../assets/image/personage/icon20.png") no-repeat
          right center;
      }
      .title:before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -10px;
        background: #3273f6;
        display: block;
        width: 4px;
        height: 20px;
        border-radius: 2px;
        content: "";
      }
      .list-item {
        padding: 15px 0px;
        border-bottom: 1px solid #ebebeb;
        .t {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.6;
        }
        .c {
          font-size: 14px;
          color: #666;
          line-height: 1.6;
          margin-top: 5px;
        }
      }
      .list-item:nth-last-child(1) {
        border: none;
      }
    }
  }
  .ment-content {
    width: 860px;
    background: #fff;
  }
  .ment-list {
    padding: 20px 30px;
    .ment-list-item {
      padding: 15px 0px;
      border-bottom: 1px solid #ebebeb;
      font-size: 14px;
      line-height: 1.6;
      .item {
        display: flex;
        line-height: 1.6;
        margin: 5px 0px;
      }
      .li {
        flex: 1;
        line-height: 1.6;
      }
      span {
        line-height: 1.6;
      }
    }
  }
}
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}
.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}
.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}
.rate_page_btn.red {
  background: #fddfdf;
  border: 1px solid #fddfdf;
  color: #ff5151;
}
.personage_ul {
  padding: 0 30px;
  li {
    padding: 30px 0px;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .tip {
      font-size: 14px;
    }
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
.blue_color {
  /deep/ .el-breadcrumb__inner {
    color: #3273f6;
  }
}
</style>
